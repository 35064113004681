import { ApiRoutes } from "../../constants/ApiRouteConstants";
import { PagerFilters } from "../../helpers/filterHelper";
import { PageResult } from "../../models/core/PageResult";
import { Rol } from "../../models/core/Rol";
import { convertToUrlParamiter } from '../../helpers/filterHelper';
import { apiGet, apiPost } from '../BaseService';


export interface RolFilters extends PagerFilters {
  buscar?: string;
  active?: boolean;
}

const listar = async (filters?: RolFilters): Promise<PageResult<Rol> | undefined> => {
  try {
    const url = ApiRoutes.roles + convertToUrlParamiter(filters);
    const models = await apiGet<PageResult<Rol>>(url);
    return models;
  } catch (err) {
    console.log(err);
  }
  return undefined;
};

const get = async (id: string): Promise<Rol | undefined> => {
  try {
    const model = await apiGet<Rol>(ApiRoutes.rol.replace(":id", id));
    return model;
  } catch (er) {}
  return undefined;
};

const update = async (model: Rol): Promise<Rol | undefined> => {
  try {
    const respose = await apiPost<Rol, Rol>(ApiRoutes.rolUpdate, model);
    return respose;
  } catch (er) {}
  return undefined;
};

// const lookup = async (): Promise<DropdownItem[] | undefined> => {
//   try {
//     const url = ApiRoutes.rolLookup;
//     const models = await apiGet<DropdownItem[]>(url);
//     return models;
//   } catch (err) {
//     console.log(err);
//   }
//   return undefined;
// };

export const RolService = {
  listar,
  get,
  update,
  // lookup
};
