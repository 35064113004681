export const routeRoots = {
  Gestion: '/gestion',
  Gremial: '/gremial',
  AppMovil: '/app',
  Config: '/config',
  Afiliacion: '/afiliacion',
  AccionSocial: '/accionsocial',
  Recaudaciones: '/recaudaciones',
  Solicitudes: '/Solicitudes',
  Inicio: '/inicio',
};

export const RouteUrl = {
  Dashboard: `${routeRoots.Gestion}/dashboard`,

  // PANEL DE CONTROL - SEGURIDAD
  Roles: `${routeRoots.Config}/roles`,
  Rol: `${routeRoots.Config}/rol`,
  RolId: `${routeRoots.Config}/rol/:Id`,

  Usuarios: `${routeRoots.Config}/usuarios`,
  Usuario: `${routeRoots.Config}/usuario`,
  UsuarioId: `${routeRoots.Config}/usuario/:id`,

  // GREMIALES - AFILIADOS
  Afiliados: `${routeRoots.Gremial}/afiliados`,
  Afiliado: `${routeRoots.Gremial}/afiliado`,
  AfiliadoId: `${routeRoots.Gremial}/afiliado/:id`,

  Empresas: `${routeRoots.Gremial}/empresas`,
  Empresa: `${routeRoots.Gremial}/empresa`,
  EmpresaId: `${routeRoots.Gremial}/empresa/:id`,

  Seccionales: `${routeRoots.Gremial}/seccionales`,
  Seccional: `${routeRoots.Gremial}/seccional`,
  SeccionalId: `${routeRoots.Gremial}/seccional/:id`,

  AppTokens: `${routeRoots.AppMovil}/app`,

  // ACCION SOCIAL - REINTEGROS

  AccionSocial: `${routeRoots.AccionSocial}/`,
  Reintegros: `${routeRoots.AccionSocial}/reintegros`,
  AyudaEscolar: `${routeRoots.AccionSocial}/ayudaescolar`,
  Pañales: `${routeRoots.AccionSocial}/pañales`,

  Expedientes: `${routeRoots.Config}/expedientes`,
  Expediente: `${routeRoots.Config}/expediente`,
  ExpedienteId: `${routeRoots.Config}/expediente/:id`,

  ReportesPadron: `${routeRoots.Afiliacion}/reportespadron`,

  Config: `${routeRoots.Config}/`,

  RolesNew: `${routeRoots.Config}/roles/nuevo`,
  RolesEdit: `${routeRoots.Config}/roles/:id`,
  RolesView: `${routeRoots.Config}/roles/:id/ver`,

  Rubros: `${routeRoots.Config}/rubros`,
  Ciclos: `${routeRoots.Config}/ciclos`,

  ListadoTemp: `${routeRoots.Afiliacion}/listadotemporal`,
  AutorizarModif: `${routeRoots.Afiliacion}/autorizarmodificacion`,
  Procesar: `${routeRoots.Afiliacion}/procesar`,
  ReportesManejoTemp: `${routeRoots.Afiliacion}/reportesmanejotemporal`,

  Solicitudes: `${routeRoots.Solicitudes}/`,
  SolicitudesEdit: `${routeRoots.Solicitudes}/:id`,
  SolicitudesView: `${routeRoots.Solicitudes}/:id/ver`,

  Recaudaciones: `${routeRoots.Recaudaciones}/`,
  Inicio: `${routeRoots.Inicio}/`,
  Home: '/',
  Login: '/login',
};
