import { apiGet, apiPost } from '../BaseService';
import { ApiRoutes } from "../../constants/ApiRouteConstants";
import { convertToUrlParamiter, PagerFilters } from "../../helpers/filterHelper";
import { Laboral } from '../../models/gremial/Laboral';


const listar = async (id:string): Promise<Laboral[] | undefined> => {
  try {
    const url = ApiRoutes.laborales.replace(":id", id);
    const models = await apiGet<Laboral[]>(url);
    return models;
  } catch (err) {
    console.log(err);
  }
  return undefined;
};
const update = async (model: Laboral): Promise<Laboral | undefined> => {
  try {
    const respose = await apiPost<Laboral, Laboral>(ApiRoutes.laboral, model);
    return respose;
  } catch (er) {}
  return undefined;
};

const finalizarTrabajo = async (id: string, date:number): Promise<Laboral | undefined> => {
  try {
    const respose = await apiPost<number, Laboral>(ApiRoutes.laboralFinalizar.replace(":id",id), date);
    return respose;
  } catch (er) {}
  return undefined;
};


export const LaboralService = {
  listar,
  finalizarTrabajo,
  update
};
