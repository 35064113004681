import { AppChangeEmailModel } from '../models/appMovil/AppToken';
export const ApiRoutes = {
  userAuth: '/user/login',
  userInfo: '/userinfo',

  funciones: '/aplicaciones/funciones',
  funcionesLookup: '/aplicaciones/funciones/lookup',

  userRegistros: '/registros',
  userRegistro: '/registros/:id',
  userRegistrosUpdate: '/registros',
  userRegistroControlado: '/registros/:id/controlado/:afiliadoid',
  userRegistroAprobado: '/registros/:id/aceptar',
  userRegistroRechazado: '/registros/:id/rechazar',

  roles: '/roles',
  rol: '/roles/:id',
  rolUpdate: '/roles',
  rolLookup: '/roles/lookup',

  usuarios: '/usuarios',
  usuario: '/usuarios/:id',
  usuarioUpdate: '/usuarios',

  appTokenList: '/appmovil/token/list',
  AppChangeEmail: '/appmovil/token/list',
  appTokenUpdate: '/appmovil/update',

  rubros: '/sas/Rubros',
  rubroUpdate: '/sas/Rubros',
  rubrosAll: '/sas/Rubros/all',

  ciclos: '/sas/ciclos',
  ciclo: '/sas/ciclos/:id',
  cicloUpdate: '/sas/ciclos',

  expedientes: '/sas/expedientes',
  expediente: '/sas/expedientes/:id',
  expedienteUpdate: '/sas/expedientes',

  ajuares: '/sas/ajuares',
  ajuar: '/sas/ajuares/:id',
  ajuarUpdate: '/sas/ajuares',

  afiliados: '/Afiliados',
  afiliado: '/afiliados/:id',
  afiliadoUpdate: '/afiliados',

  familiares: '/familiar/:id',
  familiar: '/familiar',

  laborales: '/laboral/:id',
  laboral: '/laboral',
  laboralFinalizar: '/laboral/:id/finish',

  empresas: '/empresa',
  empresa: '/empresa/:id',
  empresaUpdate: '/empresa',

  seccionales: '/seccional',
  seccional: '/seccional/:id',
  seccionalUpdate: '/seccional',

  delegacionesLookup: '/sog/dependencias/delegaciones/lookup',
};
