import { Usuario } from '../models/core/Usuario';
import jwt_decode from 'jwt-decode';
import { Permisos } from './Permisos';
import { LocalStorageService } from '../services/core/LocalStorageService';

export class AuthorizationHelper {
  private jwt?: JwtDecoded;
  private permisos?: string[];
  private usuario?: Usuario;

  constructor() {
    const token = LocalStorageService.get('token') as string;
    this.jwt = jwt_decode(token) as JwtDecoded;

    // this.userInfo = {
    //   username: decoded.username,
    //   cuentaId: decoded.cuentaId,
    //   usuarioId: decoded.usuarioId,
    //   cuit: decoded.cuit,
    //   iva: decoded.iva,
    //   env: decoded.env,
    //   ctipo: decoded.ctipo,
    // };

    this.permisos = this.jwt.functions?.split(',');
  }

  get(): Usuario | undefined {
    return this.usuario;
  }

  // cuentaTipo(): CuentaTipo {
  //   if (!this.userInfo || !this.userInfo.ctipo) {
  //     return CuentaTipo.StartUp;
  //   }
  //   return this.userInfo.ctipo;
  // }

  canAccess(permiso: Permisos): boolean {
    if (!this.jwt || !this.permisos) {
      return false;
    }
    return this.permisos?.includes(permiso);
  }

  canSomeAccess(permisos: Permisos[]): boolean {
    if (!this.jwt || !this.permisos) {
      return false;
    }
    const access = permisos.some((onePermission) =>
      this.permisos?.includes(onePermission)
    );
    return access;
  }

  canEveryAccess(permisos: Permisos[]): boolean {
    if (!this.jwt || !this.permisos) {
      return false;
    }
    const access = permisos.every((onePermission) =>
      this.permisos?.includes(onePermission)
    );
    return access;
  }

  // /**
  //  * Returns if it belongs to the account type
  //  */
  // IsAccountType(...accountTypes: AccountType[]): boolean {
  //   let access = false;
  //   if (!this.userInfo) {
  //     return false;
  //   }
  //   const account = this.userInfo.accounts.find(x => accountTypes.includes(x.accountType));
  //   if (account) {
  //     access = true;
  //   }
  //   return access;
  // }

  // CanPayForFeature(featureId: string): boolean {
  //   return this.userInfo?.account?.payableFeatures?.includes(featureId) ?? false;
  // }

  // CanPaySomeFeature(features: string[]): boolean {
  //   const userFeatures = this.userInfo?.account?.payableFeatures;
  //   if (!userFeatures) {
  //     return false;
  //   }
  //   const access = features.some(oneFeature => userFeatures.includes(oneFeature));
  //   return access;
  // }

  // /**
  //  * Returns read access to a resource type of an account
  //  */

  // CanPerformAction(permission: string): boolean {
  //   if (!this.userInfo?.account?.permissions) {
  //     return false;
  //   }
  //   const access = this.userInfo.account.permissions.includes(permission);
  //   return access;
  // }

  // CanPerformActionOrCanPay(permission: string): boolean {
  //   return this.CanPerformAction(permission) || this.CanPayForFeature(permission);
  // }

  // CanPerformSomeActionOrCanPay(permissions: string[]): boolean {
  //   return this.CanPerformSomeAction(permissions) || this.CanPaySomeFeature(permissions);
  // }

  // /**
  //  * Returns access to a hotel by an account
  //  */
  // CanAccessHotel(hotelId?: string): boolean {
  //   let access = false;
  //   if (!this.userInfo || !hotelId) {
  //     return false;
  //   }
  //   if (this.IsAccountType(AccountType.Vindow, AccountType.AirlineAgency)) {
  //     return true;
  //   }
  //   if (this.userInfo.account && this.userInfo.account.hotels) {
  //     access = this.userInfo.account.hotels.includes(hotelId);
  //   }
  //   return access;
  // }

  // /**
  //  * Returns access to a hotel by an account
  //  */
  // CanAccessCustomer(customerId: string): boolean {
  //   if (!this.userInfo?.account?.customers) {
  //     return false;
  //   }
  //   const access = this.userInfo.account.customers.map(ac => ac.customerId).includes(customerId);
  //   return access;
  // }
}
interface JwtDecoded {
  email?: string;
  userId?: string;
  functions?: string;
}
