import { Card, Col, Form, Row, Space, Table, message } from 'antd';
import React, { useCallback, useState } from 'react';
import { InputExt } from '../../../components/forms/InputExt';
import { SelectEmpresa } from '../../../components/lookups/SelectEmpresa';
import { Laboral, LaboralEstado } from '../../../models/gremial/Laboral';
import { LaboralService } from '../../../services/gremial/LaboralService';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { CardLaboralHistorial } from './CardLaboralHistorial';
import { ColumnsType } from 'antd/es/table';

export interface Properties {
  editing?: boolean;
  personaId:number;
}

export const CardLaboral: React.FC<Properties> = ({ editing,personaId}) => {
  const [data, setData] = useState<Laboral[]>();
  const [isNewComision, setNewComision] = useState<boolean>(false);
  const [showCardNew, setShowCardNew] = useState(true);
  const [hasActiveWork , setActiveWork] = useState(false);

  const cargarDatos = useCallback(async (): Promise<void> => { 
    const result = await LaboralService.listar(personaId.toString());
    if (result) {
      setData(result);
      if(result.some((i)=>i.estado ===1)){
        setActiveWork(true);
        setShowCardNew(false); 
      }
      else{
        setShowCardNew(true);
        setActiveWork(false);
      }
    
 

     
    }
  }, [data]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, []);

  
  const columns: ColumnsType<Laboral> = [
    {
      title: 'Legajo',
      key: 'legajo',
      width: 200,
      render: (_, row) => <span>{row.legajo}</span>,
    },
    {
      title: 'Empresa',
      key: 'empresa',
      width: 200,
      render: (_, row) => <span>{row.empresa?.nombre}</span>,
    },
    {
      title: 'Fecha Ingreso',
      key: 'fechaIngreso',
      width: 200,
      render: (_, row) => <span>{row.fechaIngreso?.toString()}</span>,
    },
    {
      title: 'Fecha Egreso',
      key: 'fechaEgreso',
      width: 200,
      render: (_, row) => <span>{row.fechaEgreso?.toString()}</span>,
    },
  ];
  const handleCardNewClick = () => {
    setShowCardNew(false);
  };
  return (
    <> 
   {
  !hasActiveWork ? (
    showCardNew ? (
      <Space>
        <Card className='CardNew' onClick={handleCardNewClick}>
          <i className='far fa-plus-circle fa-3x'></i>
        </Card>
      </Space>
    ) : (
      <CardLaboralHistorial
        data={{}}
        isNew={true}
        isNewComision={isNewComision}
        setNewComision={setNewComision}
        persona={personaId}
      />
    )
  ) : null
}
   
     {isNewComision &&  <CardLaboralHistorial  isNewComision={isNewComision}  setNewComision={setNewComision} persona={personaId}/> } 
    {data &&
    data
    .filter((item) => item.estado !== LaboralEstado.Baja)
    .map((item) => (
         <CardLaboralHistorial  data={item}  isNewComision={isNewComision} setNewComision={setNewComision} persona={personaId}/>
    ))}  
    {data?.filter((item)=> item.estado == LaboralEstado.Baja) && 
     <Space>
     <Card title="Trabajos Finalizados">
     <Table
         rowKey='laborald'
         columns={columns}
         dataSource={data?.filter((item)=> item.estado == LaboralEstado.Baja)}
         size='small'
         pagination={false}
       />
    </Card>
   </Space>
    } 
    </>   
  );
};
