import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SelectExt, SelectItem } from '../forms/SelectExt';
import { SelectProps } from "antd";
import { EmpresaService } from "../../services/gremial/EmpresaService";


interface properties extends SelectProps {
  value?: number;
  readOnly?: boolean;
}

export const SelectEmpresa = (props: properties): JSX.Element => {
  const { value } = props;
  const [lookups, setLookups] = useState<SelectItem[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = useCallback(async (): Promise<void> => {
    setLoading(true);
    const values = await EmpresaService.listar();
    if (values?.data) {
      const data = values.data.map(x => {
        return { value: x.empresaId ?? "", label: x.nombre ?? "" };
      });
      setLookups(data);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    void loadData();
  }, [loadData]);

  const valueText = useMemo(() => {
    return value && lookups ? lookups.find(i => i.value === value)?.value : "";
  }, [lookups, value]);

  return <SelectExt {...props} options={lookups} placeholder="Seleccionar Empresa" value={valueText} />;
};
