import { apiGet, apiPost } from '../BaseService';
import { ApiRoutes } from "../../constants/ApiRouteConstants";
import { PageResult } from "../../models/core/PageResult";
import { convertToUrlParamiter, PagerFilters } from "../../helpers/filterHelper";
import { Empresa } from "../../models/gremial/Empresa";


export interface EmpresaFilters extends PagerFilters {
  buscar?: string;
}

const listar = async (filters?: EmpresaFilters): Promise<PageResult<Empresa> | undefined> => {
  try {
    const url = ApiRoutes.empresas + convertToUrlParamiter(filters);
    const models = await apiGet<PageResult<Empresa>>(url);
    return models;
  } catch (err) {
    console.log(err);
  }
  return undefined;
};

const get = async (id: string): Promise<Empresa | undefined> => {
  try {
    const model = await apiGet<Empresa>(ApiRoutes.empresa.replace(":id", id));
    return model;
  } catch (er) {}
  return undefined;
};

const update = async (model: Empresa): Promise<Empresa | undefined> => {
  try {
    const respose = await apiPost<Empresa, Empresa>(ApiRoutes.empresaUpdate, model);
    return respose;
  } catch (er) {}
  return undefined;
};

export const EmpresaService = {
  listar,
  get,
  update
};
