import React from 'react';
import './Link.css';

interface Properties extends React.ButtonHTMLAttributes<HTMLInputElement> {
  href?: string;
}

export class Link extends React.Component<Properties> {
  render(): JSX.Element {
    const { className } = this.props;
    return (
      <a
        className={'default' + (className ? ` ${className}` : '')}
        href={this.props.href}
      >
        {this.props.children}
      </a>
    );
  }
}
