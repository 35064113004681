import { apiGet, apiPost } from '../BaseService';
import { ApiRoutes } from "../../constants/ApiRouteConstants";
import { PageResult } from "../../models/core/PageResult";
import { convertToUrlParamiter, PagerFilters } from "../../helpers/filterHelper";
import { Seccional } from "../../models/gremial/Seccional";


export interface SeccionalFilters extends PagerFilters {
  buscar?: string;
}

const listar = async (filters?: SeccionalFilters): Promise<PageResult<Seccional> | undefined> => {
  try {
    const url = ApiRoutes.seccionales + convertToUrlParamiter(filters);
    const models = await apiGet<PageResult<Seccional>>(url);
    return models;
  } catch (err) {
    console.log(err);
  }
  return undefined;
};

const get = async (id: string): Promise<Seccional | undefined> => {
  try {
    const model = await apiGet<Seccional>(ApiRoutes.seccional.replace(":id", id));
    return model;
  } catch (er) {}
  return undefined;
};

const update = async (model: Seccional): Promise<Seccional | undefined> => {
  try {
    const respose = await apiPost<Seccional, Seccional>(ApiRoutes.seccionalUpdate, model);
    return respose;
  } catch (er) {}
  return undefined;
};

export const SeccionalService = {
  listar,
  get,
  update
};
