import {
  Card,
  Col,
  Input,
  Pagination,
  Row,
  Space,
  Spin,
  Table,
  Tag,
} from 'antd';
import Button from 'antd-button-color';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useState } from 'react';

import { RouteUrl } from '../../../constants/RouteConstants';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import type { PageResult } from '../../../models/core/PageResult';
import { Rol } from '../../../models/core/Rol';
import { RolFilters, RolService } from '../../../services/core/RolService';
import { configApp } from '../../../services/configApp';
import { useHistory } from 'react-router';

export interface UsuariosProps {}

const RolList: React.FC<UsuariosProps> = () => {
  const history = useHistory();
  const [pageResult, setPageResult] = useState<PageResult<Rol>>();
  const [page, setPage] = useState<number>(1);
  const [buscar, setBuscar] = useState<string>('');

  const columns: ColumnsType<Rol> = [
    {
      title: 'Nombre',
      key: 'rolId',
      width: 600,
      render: (_, row) => (
        <a href={`${RouteUrl.Rol}/${row.rolId}`}>{row.nombre}</a>
      ),
    },
    {
      title: '',
      key: 'status',
      render: (_, row) => (
        <>
          <Tag color={row.activo ? 'green' : 'volcano'}>
            {row.activo ? 'Activo' : 'Inactivo'}
          </Tag>
          {row.esAdmin && (
            <Tag color='magenta' key='admin'>
              Admin
            </Tag>
          )}
        </>
      ),
    },
  ];

  const cargarDatos = useCallback(async (): Promise<void> => {
    const filters: RolFilters = {
      buscar,
      page: page,
      count: configApp.ROW_PER_PAGE,
    };

    const result = await RolService.listar(filters);
    console.log(result);
    if (result) {
      setPageResult(result);
    }
  }, [buscar, page]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, [page, buscar]);

  function handlerChangePage(page: number, pageSize: number): void {
    setPage(page);
  }

  return (
    <Card
      title='Roles'
      extra={
        <Space>
          <Button type='warning' onClick={() => history.push(RouteUrl.Rol)}>
            Crear nuevo
          </Button>
        </Space>
      }
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Input
            placeholder='Buscar'
            value={buscar}
            onChange={(e: any) => setBuscar(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <Spin spinning={loader.loading} delay={500}>
            <Table
              rowKey='rolId'
              columns={columns}
              dataSource={pageResult?.data}
              size='small'
              pagination={false}
            />
            {pageResult?.pagination && (
              <Pagination
                size='small'
                current={pageResult.pagination.page}
                total={pageResult?.pagination.rows}
                pageSize={pageResult?.pagination.count}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} de ${total} items`
                }
                onChange={handlerChangePage}
              />
            )}
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};

export default RolList;
