import * as React from 'react';
import type { RouteProps } from 'react-router-dom';
import { Route, Redirect } from 'react-router-dom';
// import { StoreService } from "../../../services/StoreService";
// import { routes } from "../../../constants/RouteConstants";
import { RouteUrl } from '../constants/RouteConstants';

export const PrivateRoute = (props: RouteProps): JSX.Element => {
  console.log(
    `Checking login from ${props.path} - Current location: ${props.location?.pathname}`
  );
  const isLoggedIn = true; //StoreService.checkLogin();
  const state = `${props.location?.pathname}${props.location?.search ?? ''}`;
  return isLoggedIn ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: RouteUrl.Login,
        state: { from: `${state}` },
      }}
    />
  );
};
