import React from "react";
import { ExpedienteItem } from "../../../models/sas/ExpedienteItem";
import { ExpedienteReintegro } from "../../../models/sas/ExpedienteReintegro";
import { Space, Table, TableColumnsType } from "antd";

export interface ExpedienteProps {
  items?: ExpedienteReintegro[];
}

const ExpedienteItems: React.FC<ExpedienteProps> = ({ items }) => {
  const expandedRowRender = (reintegro: ExpedienteReintegro) => {
    const columns: TableColumnsType<ExpedienteItem> = [
      { title: "Rubro", dataIndex: "item", key: "item" },
      { title: "Reintegro", dataIndex: "reintegro", key: "reintegro" },
      { title: "Orden", dataIndex: "orden", key: "orden" },
      {
        title: "Action",
        dataIndex: "operation",
        key: "operation",
        render: () => (
          <Space size="middle">
            <a>Eliminar</a>
          </Space>
        ),
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={reintegro.items}
        pagination={false}
      />
    );
  };

  const columns: TableColumnsType<ExpedienteReintegro> = [
    { title: "Detalle", dataIndex: "detalle", key: "detalle" },
    { title: "Cantidad", dataIndex: "cantidad", key: "cantidad" },
    { title: "Unitario", dataIndex: "unitario", key: "unitario" },
    { title: "Total", dataIndex: "total", key: "total" },
  ];

  return (
    <Table
      columns={columns}
      expandable={{
        expandedRowRender,
      }}
      dataSource={items}
      pagination={false}
    />
  );
};

export default ExpedienteItems;
