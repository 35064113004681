import { Card, Col, Form, Row, Switch } from 'antd';
import React from 'react';
import { InputExt } from '../../../components/forms/InputExt';
import { InputNumberExt } from '../../../components/forms/InputNumberExt';
import { DatePickerExt } from '../../../components/forms/DatePickerExt';
import { SelectSeccional } from '../../../components/lookups/SelectSeccional';
import { SelectEmpresa } from '../../../components/lookups/SelectEmpresa';

export interface Properties {
  editing?: boolean;
}

export const CardAfiliado: React.FC<Properties> = ({ editing }) => {
  return (
    <Card title='Datos Afiliatorios'>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className='gutter-row' md={6} xs={12} sm={24}>
          <Form.Item
            label='Afiliado'
            name={'socio'}
            rules={[{ required: true, message: '' }]}
          >
            <InputNumberExt readOnly={!editing} />
          </Form.Item>
        </Col>
        <Col className='gutter-row' span={6}>
          <Form.Item label='Legajo' name={'legajo'}>
            <InputExt placeholder='' maxLength={25} readOnly={!editing} />
          </Form.Item>
        </Col>
        <Col className='gutter-row' span={6}>
          <Form.Item
            label='Fecha de Alta'
            name={'fechaAlta'}
            rules={[{ required: true, message: '' }]}
          >
            <DatePickerExt disabled={!editing} />
          </Form.Item>
        </Col>
        <Col className='gutter-row' span={6}>
          <Form.Item
            label='Fecha Aprobacion'
            name={'fechaAprobacion'}
            rules={[{ required: true, message: '' }]}
          >
            <DatePickerExt disabled={!editing} />
          </Form.Item>
        </Col>
        <Col className='gutter-row' span={12}>
          <Form.Item label='Seccional' name='seccionalId'>
            <SelectSeccional readOnly={!editing} />
          </Form.Item>
        </Col>
        <Col className='gutter-row' span={12}>
          <Form.Item label='Empresa' name='empresaId'>
            <SelectEmpresa readOnly={!editing} />
          </Form.Item>
        </Col>
        <Col className='gutter-row' span={6}>
          <Form.Item
            label='Ayuda Solidaria'
            name='ayudaSolidaria'
            valuePropName='checked'
          >
            <Switch checkedChildren='Si' unCheckedChildren='No' />
          </Form.Item>
        </Col>
        <Col className='gutter-row' span={18}>
          <Form.Item label='Estado' name={['estado', 'nombre']}>
            <InputExt
              placeholder=''
              maxLength={50}
              showCount
              readOnly={!editing}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' span={24}>
          <Form.Item label='Motivo' name={'motivo'}>
            <InputExt
              placeholder=''
              maxLength={50}
              showCount
              readOnly={!editing}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};
