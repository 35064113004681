import React from 'react';
import './cardButton.css';
import { Link } from '../link/Link';
import { Icon } from '../icon/Icon';

interface properties {
  iconName: string;
  title?: string;
  subtitle?: string;
  href?: string;
  color?: 'icon-blue' | 'icon-gray' | 'icon-orange';
  style?: React.CSSProperties;
}

export const CardButton: React.FC<properties> = ({
  iconName,
  title,
  subtitle,
  href,
  color,
  style,
}) => {
  return (
    <Link href={href} className='button' style={style}>
      <button className='card-btn d-md-flex align-items-center'>
        <Icon icon={iconName} zoom={2} />
        <div className='mt-2 ml-auto texts'>
          <h5 className='card-subtitle'> {title}</h5>
          <h6 className='card-title'>{subtitle}</h6>
        </div>
      </button>
    </Link>
  );
};
