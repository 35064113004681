import { apiGet, apiPost } from '../BaseService';
import { ApiRoutes } from '../../constants/ApiRouteConstants';
import { PageResult } from '../../models/core/PageResult';
import {
  convertToUrlParamiter,
  PagerFilters,
} from '../../helpers/filterHelper';
import { Afiliado, AfiliadoListModel } from '../../models/gremial/Afiliado';
import { Expediente } from '../../models/sas/Expediente';

export interface ExpedienteFilters extends PagerFilters {
  buscar?: string;
}

const buscar = async (
  filters?: ExpedienteFilters
): Promise<PageResult<Expediente> | undefined> => {
  try {
    const url = ApiRoutes.expedientes + convertToUrlParamiter(filters);
    const models = await apiGet<PageResult<Expediente>>(url);
    console.log(url);
    return models;
  } catch (err) {
    console.log(err);
  }
  return undefined;
};

const get = async (id: string): Promise<Expediente | undefined> => {
  try {
    const model = await apiGet<Expediente>(
      ApiRoutes.expediente.replace(':id', id)
    );
    return model;
  } catch (er) {}
  return undefined;
};

const update = async (model: Expediente): Promise<Expediente | undefined> => {
  try {
    const respose = await apiPost<Expediente, Expediente>(
      ApiRoutes.expedienteUpdate,
      model
    );
    return respose;
  } catch (er) {}
  return undefined;
};

export const ExpedienteService = {
  listar: buscar,
  get,
  update,
};
