import { ApiRoutes } from '../../constants/ApiRouteConstants';
import { PagerFilters } from '../../helpers/filterHelper';
import { PageResult } from '../../models/core/PageResult';
import { convertToUrlParamiter } from '../../helpers/filterHelper';
import { apiGet, apiPost } from '../BaseService';
import { Reintegro } from '../../models/accionSocial/reintegro';
import { Rubro } from '../../models/sas/Rubro';

export interface RubrosFilters extends PagerFilters {
  buscar?: string;
  showSubRubros?: boolean;
  showItems?: boolean;
  soloActivos?: boolean;
}

const listar = async (
  filters?: RubrosFilters
): Promise<PageResult<Rubro> | undefined> => {
  try {
    const url = ApiRoutes.rubros + convertToUrlParamiter(filters);
    const models = await apiGet<PageResult<Rubro>>(url);

    return models;
  } catch (err) {
    console.log(err);
  }
  return undefined;
};

const update = async (model: Rubro): Promise<Rubro | undefined> => {
  try {
    const respose = await apiPost<Rubro, Rubro>(ApiRoutes.rubroUpdate, model);
    return respose;
  } catch (er) {}
  return undefined;
};

export const RubrosService = {
  listar,
  update,
};
