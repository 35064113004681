import { apiGet, apiPost } from '../BaseService';
import { ApiRoutes } from "../../constants/ApiRouteConstants";
import { PageResult } from "../../models/core/PageResult";
import { convertToUrlParamiter, PagerFilters } from "../../helpers/filterHelper";
import { Afiliado, AfiliadoListModel } from "../../models/gremial/Afiliado";

export interface AfiliadoFilters extends PagerFilters {
  prov?: string;
  buscar?: string;
}

const buscar = async (filters?: AfiliadoFilters): Promise<PageResult<AfiliadoListModel> | undefined> => {
  try {
    const url = ApiRoutes.afiliados + convertToUrlParamiter(filters);
    const models = await apiGet<PageResult<AfiliadoListModel>>(url);
    console.log(url)
    return models;
  } catch (err) {
    console.log(err);
  }
  return undefined;
};

const get = async (id: string): Promise<Afiliado | undefined> => {
  try {
    const model = await apiGet<Afiliado>(ApiRoutes.afiliado.replace(":id", id));
    return model;
  } catch (er) {}
  return undefined;
};

const update = async (model: Afiliado): Promise<Afiliado | undefined> => {
  try {
    const respose = await apiPost<Afiliado, Afiliado>(ApiRoutes.afiliadoUpdate, model);
    return respose;
  } catch (er) {}
  return undefined;
};

export const AfiliadoService = {
  listar: buscar,
  get,
  update
};
