import React from 'react';
import { Select, SelectProps } from 'antd';
import { InputExt } from './InputExt';

export interface SelectExtProps extends SelectProps {
  readOnly?: boolean;
}

export interface SelectItem {
  value: string | number;
  label: string;
}

export const SelectExt: React.FC<SelectExtProps> = (props) => {
  const { readOnly, style, value, options  } = props;
  if (readOnly) {
    const exists = options?.find((x) => x.value === value);
    const valueText: string = exists?.label?.toString() ?? '';
    return <InputExt value={valueText} style={style} readOnly/>;
  }

  return <Select {...props} value={value} />;
};
