import {
  Card,
  Col,
  Form,
  Row,
  Skeleton,
  Space,
  Spin,
  Modal,
  message,
  DatePicker,
  DatePickerProps,
} from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { Laboral, LaboralBaja, LaboralEstado } from '../../../models/gremial/Laboral';
import { ColumnsType } from 'antd/es/table';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { LaboralService } from '../../../services/gremial/LaboralService';
import { SelectEmpresa } from '../../../components/lookups/SelectEmpresa';
import { InputExt } from '../../../components/forms/InputExt';
import Button from 'antd-button-color';
import { DatePickerExt } from '../../../components/forms/DatePickerExt';
import locale from 'antd/es/date-picker/locale/es_ES';
import { PickerProps, PickerTimeProps } from 'antd/es/date-picker/generatePicker';


export interface Properties {
  data?: Laboral;
  isNewComision: boolean;
  isNew?:boolean;
  setNewComision: React.Dispatch<React.SetStateAction<boolean>>;
  persona:number;
}

export const CardLaboralHistorial: React.FC<Properties> = ({ data, isNewComision, setNewComision,persona,isNew}) => {

  const [form] = Form.useForm<Laboral>();
  const [messageApi, contextHolder] = message.useMessage();
  const [editing, setEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [finishDate, setFinishDate] = useState(Date.now);
  const dateFormat = 'DD/MM/YYYY';
  const cargarDatos = useCallback(async (): Promise<void> => {
    setLoading(true);
    if(isNew){
      setTitle('Trabajo Nuevo');
      setEditing(true);
    }
    if (data) {
      form.setFieldsValue(data);
      setLoading(false);
      if (data.estado === 1) {
        setTitle('Trabajo Actual');
      } else if (data.estado === 2) {
        setTitle('Trabajo Comisionado');
      }
    } else {
      if(isNewComision){
        form.resetFields();
        setEditing(true);
        setLoading(false);
      }
    }
    
  }, [data]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, []);

  async function onFinish(values: Laboral): Promise<void> {
    let result;
    if(isNewComision){
      values.estado = LaboralEstado.Comisionado
    } 
      values.personaId = persona ?? 0
      result = await LaboralService.update(values);
    
    if (result) {
      messageApi.open({
        type: 'success',
        content: 'Guardado exitoso',
      });
      setNewComision(false);
      setEditing(false)
      form.resetFields();
    } else {
      messageApi.open({
        type: 'error',
        content: 'Se produjo un error al guardar',
      });
    }
  }
  const handleAddComision  = () => {
    setNewComision(true);
  };
  const handleFinishWork  = () => {
    showModal();
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
  try {
    form.setFieldValue("estado", LaboralEstado.Baja);
    form.submit()
    
  } catch (error) {
    
  }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleButtonCancel = () => {
    setNewComision(false);
    setEditing(false)
  };

  return (
    
    <Form
    form={form}
    name='quickForm'
    layout='vertical'

    autoComplete='off'
    disabled={!editing}
    onFinish={onFinish}
  >
 
      <Skeleton loading={loading} active>
      <Card title={title}  extra={
             <Space>
               {!editing && (
                 <>
                   <Button type='warning' onClick={() => setEditing(true)}>
                     Editar
                   </Button>
                   {data?.estado == LaboralEstado.Activo && (  
                      <Button type='lightdark' onClick={handleAddComision} disabled={isNewComision}>
                      Agregar Comision
                     </Button>
                   )}
                 </>        
               )}
               {editing && (
                 <>
                   <Button type='warning' onClick={handleButtonCancel}>
                     Cancelar
                   </Button>
                   <Button type='success' onClick={() => form.submit()}>
                     Grabar
                   </Button>
                   {data?.estado == LaboralEstado.Activo && (  
                      <Button type='dark' onClick={handleFinishWork}>
                      Finalizar
                     </Button>
                   )}
                   {data?.estado == LaboralEstado.Comisionado && (  
                      <Button type='lightdark'  onClick={handleFinishWork}>
                      Finalizar Comision
                     </Button>
                   )}
                 </>         
               )}
             </Space>
           }>
            <Modal title="Finalizar Trabajo" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
           
            <Form.Item label='Fecha de Egreso' name="fechaEgreso"  rules={[{ required: true, message: '' }]}>
            <DatePickerExt disabled={!editing} />
        
        </Form.Item>
       
          </Modal>
       <Form.Item name='estado' hidden />
       <Form.Item name='laboralId' hidden />
       <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col className='gutter-row' span={6}>
          <Form.Item label="Empresa" name="empresaId">
          <SelectEmpresa readOnly={!editing} />
        </Form.Item>
      </Col>
      <Col className='gutter-row' span={6}>
        <Form.Item label='Lugar de Trabajo' name={['lugarTrabajo', 'nombre']}>
          <InputExt maxLength={50} showCount readOnly={!editing} />
        </Form.Item>
      </Col>
      <Col className='gutter-row' span={6}>
        <Form.Item label='Fecha de Ingreso' name="fechaIngreso">
        <DatePickerExt disabled={!editing} />
        </Form.Item>
      </Col>
      <Col className='gutter-row' span={6}>
        <Form.Item label='F. Ing. Direccion' name="fechaIngresoDireccion">
        <DatePickerExt disabled={!editing} />
        </Form.Item>
      </Col>

      <Col className='gutter-row' span={6}>
        <Form.Item label='Cod./Legajo Int.' name="legajo">
          <InputExt maxLength={50} showCount readOnly={!editing} />
        </Form.Item>
      </Col>
      <Col className='gutter-row' span={6}>
        <Form.Item label='Detalle' name="observaciones">
          <InputExt maxLength={50} showCount readOnly={!editing} />
        </Form.Item>
      </Col>
    </Row>
   </Card>
   </Skeleton>


    

</Form>
  );
};
