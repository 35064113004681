import { Card, Col, Input, Pagination, Row, Space, Spin, Table } from 'antd';
import Button from 'antd-button-color';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteUrl } from '../../../constants/RouteConstants';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import type { PageResult } from '../../../models/core/PageResult';
import type { Empresa } from '../../../models/gremial/Empresa';
import { configApp } from '../../../services/configApp';
import {
  EmpresaFilters,
  EmpresaService,
} from '../../../services/gremial/EmpresaService';
import { AuthorizationHelper } from '../../../helpers/AuthorizationHelper';
import { Permisos } from '../../../helpers/Permisos';

export interface EmpresaProps {}

const EmpresaList: React.FC<EmpresaProps> = () => {
  const [pageResult, setPageResult] = useState<PageResult<Empresa>>();
  const [page, setPage] = useState<number>(1);
  const [buscar, setBuscar] = useState<string>('');
  const history = useHistory();
  const auth = new AuthorizationHelper();

  const columns: ColumnsType<Empresa> = [
    {
      title: 'Empresa',
      key: 'empresa',
      render: (_, row) => (
        <a href={`${RouteUrl.Empresa}/${row.empresaId}`}>{row.nombre}</a>
      ),
    },
    {
      title: 'Codigo',
      key: 'codigo',
      width: 100,
      render: (_, row) => <span>{row.codigo}</span>,
    },
    {
      title: 'CUIT',
      key: 'cuit',
      width: 200,
      render: (_, row) => <span>{row.cuit}</span>,
    },
  ];
  const cargarDatos = useCallback(async (): Promise<void> => {
    const filters: EmpresaFilters = {
      buscar,
      page: page,
      count: configApp.ROW_PER_PAGE,
    };

    const result = await EmpresaService.listar(filters);
    console.log(result);
    if (result) {
      setPageResult(result);
    }
  }, [buscar, page]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, [page, buscar]);

  function handlerChangePage(page: number, pageSize: number): void {
    setPage(page);
  }

  const canEmpresas = auth.canAccess(Permisos.sog_empresas);

  return (
    <Card
      title='Empresas'
      extra={
        <Space>
          {canEmpresas && (
            <Button
              type='warning'
              onClick={() => history.push(RouteUrl.Empresa)}
            >
              Crear nuevo
            </Button>
          )}
        </Space>
      }
    >
      <Row>
        <Col xs={24} sm={24} md={16}>
          <Input
            placeholder='Buscar'
            value={buscar}
            onChange={(e: any) => setBuscar(e.target.value)}
          />
        </Col>
      </Row>

      <Spin spinning={loader.loading} delay={500}>
        <Table
          rowKey='empresaId'
          columns={columns}
          dataSource={pageResult?.data}
          size='small'
          pagination={false}
        />
        {pageResult?.pagination && (
          <Pagination
            size='small'
            current={pageResult.pagination.page}
            total={pageResult?.pagination.rows}
            pageSize={pageResult?.pagination.count}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} de ${total} items`
            }
            onChange={handlerChangePage}
          />
        )}
      </Spin>
    </Card>
  );
};

export default EmpresaList;
