import { apiGet, apiPost } from '../BaseService';
import { ApiRoutes } from "../../constants/ApiRouteConstants";
import { convertToUrlParamiter, PagerFilters } from "../../helpers/filterHelper";
import { Familiar } from '../../models/gremial/Familiar';


const listar = async (id:string): Promise<Familiar[] | undefined> => {
  try {
    const url = ApiRoutes.familiares.replace(":id", id);
    const models = await apiGet<Familiar[]>(url);
    return models;
  } catch (err) {
    console.log(err);
  }
  return undefined;
};
const update = async (model: Familiar): Promise<Familiar | undefined> => {
  try {
    const respose = await apiPost<Familiar, Familiar>(ApiRoutes.familiar, model);
    return respose;
  } catch (er) {}
  return undefined;
};

export const FamiliarService = {
  listar,
  update
};
