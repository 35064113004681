import { Afiliado } from '../gremial/Afiliado';
import { Familiar } from '../gremial/Familiar';
import { ExpedienteReintegro } from './ExpedienteReintegro';
import { Rubro } from './Rubro';

export interface Expediente {
  expedienteId: number;
  numero?: number;
  fecha?: Date;
  comprobante?: string;
  cuit?: string;
  proveedor: string;
  afiliadoId?: number;
  afiliado?: Afiliado;
  beneficiarioId?: number;
  beneficiario?: Familiar;
  observaciones?: string;
  rubroId?: number;
  rubro?: Rubro;
  total?: number;
  reintegro?: number;
  estado?: ExpedienteEstados;
  fechaEstado?: Date;
  estadoDetalle?: string;
  reintegros?: ExpedienteReintegro[];
}

export enum ExpedienteEstados {
  Anulado,
  Pendiente,
  Aprobado,
  Rechazado,
}

export enum ExpedienteEstadosColor {
  gray,
  yellow,
  green,
  volcano,
}
