import { Card, Col, Form, Row, Space, Spin, Tabs, message } from 'antd';
import Button from 'antd-button-color';
import React, { useCallback, useState } from 'react';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { Afiliado } from '../../../models/gremial/Afiliado';
import { AfiliadoService } from '../../../services/gremial/AfiliadoService';
import { CardAfiliado } from './CardAfiliado';
import { CardContacto } from './CardContacto';
import { CardDomicilio } from './CardDomicilio';
import { CardPersonal } from './CardPersonal';
import { CardLaboral } from './CardLaboral';
import { CardFamiliares } from './familiares/CardFamiliares';
import { AuthorizationHelper } from '../../../helpers/AuthorizationHelper';
import { Permisos } from '../../../helpers/Permisos';

export interface AfiliadoProps {}

const AfiliadoForm: React.FC<AfiliadoProps> = () => {
  const [editing, setEditing] = useState<boolean>(false);
  const [form] = Form.useForm<Afiliado>();
  const [isNew, setIsNew] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const auth = new AuthorizationHelper();

  const cargarDatos = useCallback(async (): Promise<void> => {
    const urlSection = window.location.pathname.split('/');
    const id = urlSection[3];
    const result = await AfiliadoService.get(id);
    if (result) {
      form.setFieldsValue(result);
      console.log(result);
    } else {
      setEditing(true);
      setIsNew(true);
    }
  }, [form]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, []);

  async function onFinish(values: Afiliado): Promise<void> {
    const result = await AfiliadoService.update(values);
    console.log(values);
    if (result) {
      messageApi.open({
        type: 'success',
        content: 'Guardado exitoso',
      });
      setEditing(false);
    } else {
      messageApi.open({
        type: 'error',
        content: 'Se produjo un error al guardar',
      });
    }
  }

  const canAfiliados = auth.canAccess(Permisos.sog_afiliados);

  return (
    <Spin spinning={loader.loading} delay={500}>
      {contextHolder}
      <Card
        title='Ficha de Afiliado'
        extra={
          <Space>
            {canAfiliados && !editing && (
              <Button type='warning' onClick={() => setEditing(true)}>
                Editar
              </Button>
            )}
            {editing && (
              <>
                <Button type='warning' onClick={() => setEditing(false)}>
                  Cancelar
                </Button>
                <Button type='success' onClick={() => form.submit()}>
                  Grabar
                </Button>
              </>
            )}
          </Space>
        }
      >
        <Tabs
          defaultActiveKey='1'
          items={[
            {
              label: 'Datos personales / Afiliación',
              key: '1',
              children: (
                <Form
                  form={form}
                  name='quickForm'
                  layout='vertical'
                  autoComplete='off'
                  disabled={!editing}
                  onFinish={onFinish}
                >
                  <Form.Item name='afiliadoId' hidden />
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col md={24} lg={12}>
                      <CardPersonal editing={editing} />
                    </Col>
                    <Col md={24} lg={12}>
                      <CardAfiliado editing={editing} />
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={24}>
                      <CardContacto editing={editing} />
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={24}>
                      <CardDomicilio editing={editing} />
                    </Col>
                  </Row>
                </Form>
              ),
            },
            {
              label: 'Laboral',
              key: '3',
              children: (
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={24}>
                    <CardLaboral
                      editing={editing}
                      personaId={form.getFieldValue('personaId')}
                    />
                  </Col>
                </Row>
              ),
            },
            {
              label: 'Familiares',
              key: '4',
              children: (
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={24}>
                    <CardFamiliares
                      personaId={form.getFieldValue('personaId')}
                    />
                  </Col>
                </Row>
              ),
            },
          ]}
        />
      </Card>
    </Spin>
  );
};

export default AfiliadoForm;
