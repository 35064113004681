import { Card, Col, Form, Row, Space, DatePicker } from 'antd';
import React from 'react';
import { InputExt } from '../../../components/forms/InputExt';
import { InputNumberExt } from '../../../components/forms/InputNumberExt';
import { DatePickerExt } from '../../../components/forms/DatePickerExt';
import {
  SelectDocumentoTipo,
  SelectGenero,
  SelectNacionalidad,
} from '../../../components/lookups/enumLookups';

export interface Properties {
  editing?: boolean;
}

export const CardPersonal: React.FC<Properties> = ({ editing }) => {
  return (
    <Card title='Datos Personales'>
      <Form.Item name={['persona', 'personaId']} hidden />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} wrap={true}>
        <Col className='gutter-row' xs={24} md={12}>
          <Form.Item label='Documento'>
            <Space.Compact>
              <Form.Item name={['persona', 'documentoTipo']}>
                <SelectDocumentoTipo readOnly={!editing} />
              </Form.Item>
              <Form.Item
                name={['persona', 'documentoNumero']}
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <InputNumberExt readOnly={!editing} style={{ width: '100%' }} />
              </Form.Item>
            </Space.Compact>
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} md={12}>
          <Form.Item label='C.U.I.L.' name={['persona', 'cuil']}>
            <InputExt
              placeholder=''
              maxLength={13}
              showCount
              readOnly={!editing}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} md={12}>
          <Form.Item
            label='Apellido/s'
            name={['persona', 'apellido']}
            rules={[{ required: true, message: 'Falta el apellido' }]}
          >
            <InputExt
              placeholder='Apellido/s'
              maxLength={50}
              showCount
              readOnly={!editing}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} md={12}>
          <Form.Item
            label='Nombre/s'
            name={['persona', 'nombre']}
            rules={[{ required: true, message: 'Falta el apellido' }]}
          >
            <InputExt
              placeholder='Nombre/s'
              maxLength={50}
              showCount
              readOnly={!editing}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} md={12}>
          <Form.Item
            label='Fecha de nacimiento'
            name={['persona', 'fechaNacimiento']}
          >
            <DatePickerExt disabled={!editing} />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} md={12}>
          <Form.Item label='Genero' name={['persona', 'genero']}>
            <SelectGenero readOnly={!editing} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} md={12}>
          <Form.Item label='Nacionalidad' name={['persona', 'nacionalidad']}>
            <SelectNacionalidad readOnly={!editing} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} md={12}>
          <Form.Item
            label='Lugar Nacimiento'
            name={['persona', 'lugarNacimiento']}
          >
            <InputExt
              placeholder=''
              maxLength={50}
              showCount
              readOnly={!editing}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};
