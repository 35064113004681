export enum DocumentoTipo {
  Desconocido = 0,
  DNI = 1,
  LE = 2,
  LC = 3,
  CI = 4,
  Pasaporte = 90,
  Certificado = 91,
}

export enum Genero {
  Desconocido = 0,
  Femenino = 1,
  Masculino = 2,
}

export enum EstadoCivil {
  Desconocido = 0,
  Casado = 1,
  Soltero = 2,
  Viudo = 3,
  Divorciado = 4,
  Concubino = 5,
}
export enum RelacionFamiliar {
  Desconocido = 0,
  Conyuge = 1,
  Hijo = 2,
  FamiliarAcargo = 3,
}
export enum Nacionalidad {
  Desconocido = 0,
  Argentino = 1,
  Extranjero = 2,
  Nacionalizado = 3,
}
export enum Provincia {
  Desconocido = 0,
  Buenos_Aires = 1,
  CABA = 2,
  Catamarca = 3,
  Chaco = 4,
  Chubut = 5,
  Cordoba = 6,
  Corrientes = 7,
  Entre_Rios = 8,
  Formosa = 9,
  Jujuy = 10,
  La_Pampa = 11,
  La_Rioja = 12,
  Mendoza = 13,
  Misiones = 14,
  Neuquen = 15,
  Rio_Negro = 16,
  Salta = 17,
  San_Juan = 18,
  San_Luis = 19,
  Santa_Cruz = 20,
  Santa_Fe = 21,
  Santiago_del_Estero = 22,
  Tierra_del_fuego = 23,
  Tucuman = 24,
}
