import React from 'react';
// import styles from './Icon.module.scss';

export enum IconStyle {
  Regular = 'fa',
  Brand = 'fas',
}

export interface IconProperties {
  family?: IconStyle;
  icon: string;
  zoom?: number;
  className?: string;
  style?: React.CSSProperties;
}

export const Icon: React.FC<IconProperties> = ({
  family,
  icon,
  zoom,
  className,

  style,
}) => {
  let faClassName = `${family ?? 'fa'} ${icon}`;
  if (zoom) {
    faClassName += ` fa-${zoom}x`;
  }
  if (className) {
    faClassName += ` ${className}`;
  }
  return <i className={faClassName} style={style}></i>;
};
