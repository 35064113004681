import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";

import { PrivateRoute } from "./components/PrivateRoute";
import { RouteUrl, routeRoots } from "./constants/RouteConstants";
import "./index.css";
import Login from "./pages/public/login/Login";
import reportWebVitals from "./reportWebVitals";
import "./styles/SiderStyleExt.css";
import App from "./App";

const history = createBrowserHistory();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <MaintenanceProvider> */}
      <Switch>
        {/* <PrivateRoute exact path={routes.Home} component={AppPage} /> */}
        <PrivateRoute path={routeRoots.Gremial} component={AppPage} />
        <PrivateRoute path={routeRoots.Gestion} component={AppPage} />
        <PrivateRoute path={routeRoots.AppMovil} component={AppPage} />
        <PrivateRoute path={routeRoots.Config} component={AppPage} />
        <PrivateRoute path={routeRoots.AccionSocial} component={AppPage} />
        <Route path="/">
          <Login />
        </Route>
        <Route path={RouteUrl.Login}>
          <Login />
        </Route>
      </Switch>
      {/* </MaintenanceProvider> */}
    </BrowserRouter>
  </React.StrictMode>
);

function AppPage(): JSX.Element {
  return <App />;
}
