import type { PageResult } from '../../../models/core/PageResult';
import {
  Card,
  Col,
  Input,
  Modal,
  Pagination,
  Row,
  Spin,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd';
import Button from 'antd-button-color';
import React, { useCallback, useState } from 'react';
import { RouteUrl } from '../../../constants/RouteConstants';
import { useAsyncCall } from '../../../hooks/useAsyncCall';

import { configApp } from '../../../services/configApp';
import { AppToken } from '../../../models/appMovil/AppToken';
import {
  TokenService,
  AppTokenFilters,
} from '../../../services/gremial/TokenService';
import Column from 'antd/es/table/Column';
import { useHistory } from 'react-router';
import { ExclamationCircleFilled } from '@ant-design/icons';
const { confirm } = Modal;

export interface Properties {}

const TokenList: React.FC<Properties> = () => {
  const [pageResult, setPageResult] = useState<PageResult<AppToken>>();
  const [page, setPage] = useState<number>(1);
  const [buscar, setBuscar] = useState<string>('');
  const [changeEmail, setChangeEmail] = useState<string>('');
  const [messageApi, contextHolder] = message.useMessage();
  const [currentSocio, setCurrentSocio] = useState<number>();
  const [isModalChangeEmail, setIsModalChangeEmail] = useState(false);
  const history = useHistory();

  const cargarDatos = useCallback(async (): Promise<void> => {
    const filters: AppTokenFilters = {
      buscar,
      page: page,
      count: configApp.ROW_PER_PAGE,
    };

    const result = await TokenService.listar(filters);
    if (result) {
      setPageResult(result);
    }
  }, [buscar, page]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, [page, buscar]);

  function handlerChangePage(page: number, pageSize: number): void {
    setPage(page);
  }
  const handleModalEmail = (socio: number) => {
    setIsModalChangeEmail(true);
    setCurrentSocio(socio);
  };

  const handlerChangeEmailOk = useCallback(async (): Promise<void> => {
    try {
      const result = await TokenService.changeEmail({
        socio: currentSocio,
        email: changeEmail,
      });
      if (result) {
        messageApi.open({
          type: 'success',
          content: 'Guardado exitoso',
        });
      } else {
        messageApi.open({
          type: 'error',
          content: 'Se produjo un error al guardar',
        });
      }
      setChangeEmail('');
    } catch (error) {}
    setIsModalChangeEmail(false);
  }, [changeEmail, currentSocio, messageApi]);

  const handleChangeEmailCancel = () => {
    setIsModalChangeEmail(false);
    setChangeEmail('');
  };

  const showConfirm = () => {
    confirm({
      title: 'Desea Reenviar confirmacion?',
      icon: <ExclamationCircleFilled />,
      content: 'Se reenviara la confirmacion del token al correo del socio',
      onOk() {
        console.log('OK');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <Card title='Tokens de accesos a la AppMovil'>
      <Modal
        title='Agregar Email'
        open={isModalChangeEmail}
        onOk={handlerChangeEmailOk}
        onCancel={handleChangeEmailCancel}
      >
        <Input
          placeholder='Ingresar Email'
          onChange={(e: any) => setChangeEmail(e.target.value)}
        />
      </Modal>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={16}>
          <Input
            placeholder='Buscar'
            value={buscar}
            onChange={(e: any) => setBuscar(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <Spin spinning={loader.loading} delay={500}>
            <Table<AppToken>
              dataSource={pageResult?.data}
              size='small'
              pagination={false}
            >
              <Column
                title='Afiliado'
                dataIndex='socio'
                key='socio'
                width={100}
                render={(_, row: AppToken) => (
                  <a href={`${RouteUrl.Afiliado}/${row.afiliadoId}`}>
                    {row.socio}
                  </a>
                )}
              />
              <Column
                title='Documento'
                dataIndex='documentoNumero'
                key='documentoNumero'
                width={100}
              />
              <Column
                title='Apellido/s y nombre/s'
                dataIndex='nombreApellido'
                key='apellido'
                render={(_, row: AppToken) => (
                  <span>{`${row.apellido}, ${row.nombre}`}</span>
                )}
              />
              <Column title='F.Alta' dataIndex='fechaAlta' key='fechaAlta' />
              <Column
                title='Email'
                dataIndex='email'
                key='email'
                render={(_, row: AppToken) =>
                  row.email ? (
                    <span>{`${row.email}`}</span>
                  ) : (
                    <Button
                      type='warning'
                      onClick={() => handleModalEmail(row.socio ?? 0)}
                    >
                      <i className='fas fa-envelope'></i>&nbsp; Agregar Email
                    </Button>
                  )
                }
              />

              <Column
                title='Validacion'
                dataIndex='publicToken'
                key='publicToken'
              />
              <Column
                title='Estado'
                dataIndex='tokenEstado'
                key='tokenEstado'
                render={(_, row: AppToken) => (
                  <>
                    {row.tokenEstado === 0 && (
                      <Tag color='volcano'>Anulada</Tag>
                    )}
                    {row.tokenEstado === 1 && <Tag color='blue'>Pendiente</Tag>}
                    {row.tokenEstado === 2 && <Tag color='green'>Activa</Tag>}
                    {row.tokenEstado === 3 && (
                      <Tag color='violet'>Remplazada</Tag>
                    )}
                  </>
                )}
              />
              <Column
                title=''
                dataIndex='acciones'
                key='acciones'
                render={(_, row: AppToken) =>
                  row.tokenEstado === 1 && row.email ? (
                    <Tooltip
                      placement='topLeft'
                      title='Reenviar correo electronico'
                    >
                      <Button size='small' type='success' onClick={showConfirm}>
                        <i className='far fa-envelope'></i>
                      </Button>
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              />
            </Table>
            {pageResult?.pagination && (
              <Pagination
                size='small'
                current={pageResult.pagination.page}
                total={pageResult?.pagination.rows}
                pageSize={pageResult?.pagination.count}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} de ${total} items`
                }
                onChange={handlerChangePage}
              />
            )}
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};

export default TokenList;
