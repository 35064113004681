import { Card, Col, Form, Row, Space, Spin, message } from "antd";
import Button from "antd-button-color";
import React, { useCallback, useState } from "react";
import { InputExt } from "../../../components/forms/InputExt";
import { useAsyncCall } from "../../../hooks/useAsyncCall";

import { RouteUrl } from "../../../constants/RouteConstants";
import { useHistory } from "react-router";
import { Expediente } from "../../../models/sas/Expediente";
import { ExpedienteService } from "../../../services/accionSocial/ExpedienteService";
import { DatePickerExt } from "../../../components/forms/DatePickerExt";
import ExpedienteItems from "./ExpedienteItems";
import { SelectRubro } from "../../../components/lookups/SelectRubro";
import { LookupAfiliado } from "../../../components/lookups/LookupAfiliado";
import { SelectBeneficiario } from "../../../components/lookups/SelectBeneficiario";

export interface ExpedienteProps {}

const ExpedienteForm: React.FC<ExpedienteProps> = () => {
  const [editing, setEditing] = useState<boolean>(false);
  const [form] = Form.useForm<Expediente>();
  const [isNew, setIsNew] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const history = useHistory();

  const [selectedPersonaId, setSelectedPersonaId] = useState<
    number | undefined
  >(undefined);
  const [showSelectBeneficiario, setShowSelectBeneficiario] = useState(false);

  const cargarDatos = useCallback(async (): Promise<void> => {
    const urlSection = window.location.pathname.split("/");
    const id = urlSection[3];
    const result = await ExpedienteService.get(id);
    if (result) {
      form.setFieldsValue(result);
      console.log(result);
    } else {
      setEditing(true);
      setIsNew(true);
    }
  }, [form]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, []);

  async function onFinish(values: Expediente): Promise<void> {
    const result = await ExpedienteService.update(values);
    console.log(values);
    if (result) {
      messageApi.open({
        type: "success",
        content: "Guardado exitoso",
      });
      setEditing(false);
    } else {
      messageApi.open({
        type: "error",
        content: "Se produjo un error al guardar",
      });
    }
  }

  return (
    <Spin spinning={loader.loading} delay={500}>
      {contextHolder}
      <Card
        title="Expediente"
        extra={
          <Space>
            {!editing && (
              <>
                <Button type="warning" onClick={() => setEditing(true)}>
                  Editar
                </Button>
              </>
            )}
            {editing && (
              <>
                <Button
                  type="warning"
                  onClick={() =>
                    isNew ? history.push(RouteUrl.Empresas) : setEditing(false)
                  }
                >
                  Cancelar
                </Button>
                <Button type="success" onClick={() => form.submit()}>
                  Grabar
                </Button>
              </>
            )}
          </Space>
        }
      >
        <Form
          form={form}
          name="quickForm"
          layout="vertical"
          autoComplete="off"
          disabled={!editing}
          onFinish={onFinish}
        >
          <Form.Item name="expedienteId" hidden />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Numero"
                name="numero"
                rules={[{ required: true, message: "Falta el numero" }]}
              >
                <InputExt
                  placeholder="numero"
                  //style={{ width: '50%' }}
                  maxLength={5}
                  showCount
                  readOnly={!editing}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Comprobante"
                name="comprobante"
                rules={[{ required: true, message: "Falta el comprobante" }]}
              >
                <InputExt
                  placeholder="comprobante"
                  // style={{ width: '50%' }}
                  maxLength={50}
                  showCount
                  readOnly={!editing}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="CUIT"
                name="cuit"
                required
                rules={[{ required: true, message: "Falta el cuit" }]}
              >
                <InputExt
                  placeholder="CUIT"
                  maxLength={50}
                  showCount
                  readOnly={!editing}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item label="Fecha" name="fecha">
                <DatePickerExt disabled={!editing} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Proveedor"
                name="proveedor"
                required
                rules={[{ required: true, message: "Falta el Proveedor" }]}
              >
                <InputExt
                  placeholder="Proveedor"
                  maxLength={50}
                  showCount
                  readOnly={!editing}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item label="Rubro" name="rubroId">
                <SelectRubro readOnly={!editing} />
              </Form.Item>
            </Col>
            {form.getFieldValue("afiliado") ? (
              <>
                <Col className="gutter-row" span={6}>
                  <Form.Item
                    label="Afiliado"
                    name={["afiliado", "socio"]}
                    required
                    rules={[{ required: true, message: "Falta el Afiliado" }]}
                  >
                    <InputExt
                      placeholder="afiliado"
                      maxLength={50}
                      showCount
                      readOnly={!editing}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    label="Nombre/s y Apellido/s"
                    name={["afiliado", "persona", "nombres"]}
                    required
                    rules={[{ required: true, message: "Falta el nombre" }]}
                  >
                    <InputExt
                      placeholder="afiliado"
                      maxLength={50}
                      showCount
                      readOnly={!editing}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Form.Item
                    label="Documento"
                    name={["afiliado", "persona", "documento"]}
                    required
                    rules={[{ required: true, message: "Falta el documento" }]}
                  >
                    <InputExt
                      placeholder="afiliado"
                      maxLength={50}
                      showCount
                      readOnly={!editing}
                    />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Afiliado"
                  name={["afiliado", "socio"]}
                  required
                  rules={[{ required: true, message: "Falta el Afiliado" }]}
                >
                  <LookupAfiliado
                    setSelectedPersonaId={setSelectedPersonaId}
                    setShowSelectBeneficiario={setShowSelectBeneficiario}
                  />
                </Form.Item>
              </Col>
            )}

            <Col className="gutter-row" xs={6} md={12}>
              <Form.Item
                label="Beneficiario"
                name={["beneficiario", "nombre"]}
                required
                rules={[{ required: true, message: "Falta el Beneficiario" }]}
              >
                {form.getFieldValue("beneficiario") ? (
                  <InputExt
                    placeholder="beneficiario"
                    maxLength={50}
                    showCount
                    readOnly={!editing}
                  />
                ) : (
                  <SelectBeneficiario
                    personaId={selectedPersonaId ?? 1}
                    disabled={!showSelectBeneficiario}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Card title="Reintegros">
            <Form.Item name="reintegros" hidden />
            <ExpedienteItems items={form.getFieldValue("reintegros")} />
          </Card>
          <Card>
            <Row>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label="Total"
                  name="total"
                  required
                  rules={[{ required: true, message: "Falta el Total" }]}
                >
                  <InputExt
                    placeholder="Total"
                    maxLength={50}
                    showCount
                    readOnly={!editing}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label="Reintegro"
                  name="reintegro"
                  required
                  rules={[{ required: true, message: "Falta el Reintegro" }]}
                >
                  <InputExt
                    placeholder="Reintegro"
                    maxLength={50}
                    showCount
                    readOnly={!editing}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Card>
    </Spin>
  );
};

export default ExpedienteForm;
