export enum Permisos {
  sys_pcontrol = '10',
  sys_security = '11',
  sys_security_roles = '12',
  sys_security_users = '13',

  sog_modulo = '20',
  sog_empresas = '21',
  sog_seccionales = '22',
  sog_afiliados = '23',
}
