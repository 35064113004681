import React, { ReactNode } from 'react';

interface LabelProps {
  text?: string;
  children: ReactNode;
}

const Label: React.FC<LabelProps> = ({ text, children }) => {
  const textStyle: React.CSSProperties = {
    fontSize: '12px',
 
  };

  const mainLabelStyle: React.CSSProperties = {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '-8px'
  };
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column'
  };

  return (
    <div style={containerStyle}>
      {text && <label style={textStyle}>{text}</label>}
      <label style={mainLabelStyle}>{children}</label>
    </div>
  );
};

export default Label;
