import { Card, Col, Form, Row, Space, Spin, Switch, message } from 'antd';
import Button from 'antd-button-color';
import React, { useCallback, useState } from 'react';

import { InputExt } from '../../../components/forms/InputExt';
import { RouteUrl } from '../../../constants/RouteConstants';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { Seccional } from '../../../models/gremial/Seccional';
import { SeccionalService } from '../../../services/gremial/SeccionalService';
import { useHistory } from 'react-router';
import { AuthorizationHelper } from '../../../helpers/AuthorizationHelper';
import { Permisos } from '../../../helpers/Permisos';

export interface SeccionalProps {}
const SeccionalForm: React.FC<SeccionalProps> = () => {
  const history = useHistory();
  const [editing, setEditing] = useState<boolean>(false);
  const [form] = Form.useForm<Seccional>();
  const [isNew, setIsNew] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const auth = new AuthorizationHelper();

  const cargarDatos = useCallback(async (): Promise<void> => {
    const urlSection = window.location.pathname.split('/');
    const id = urlSection[3];
    if (id) {
      const result = await SeccionalService.get(id);
      if (result) {
        form.setFieldsValue(result);
      }
    } else {
      setEditing(true);
      setIsNew(true);
    }
  }, [form]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, []);

  async function onFinish(values: Seccional): Promise<void> {
    const result = await SeccionalService.update(values);
    console.log(values);
    if (result) {
      messageApi.open({
        type: 'success',
        content: 'Guardado exitoso',
      });
      setEditing(false);
    } else {
      messageApi.open({
        type: 'error',
        content: 'Se produjo un error al guardar',
      });
    }
  }

  const canSeccionales = auth.canAccess(Permisos.sog_seccionales);

  return (
    <Spin spinning={loader.loading} delay={500}>
      {contextHolder}
      <Card
        title='Ficha de Seccional'
        extra={
          <Space>
            {canSeccionales && !editing && (
              <>
                <Button type='warning' onClick={() => setEditing(true)}>
                  Editar
                </Button>
              </>
            )}
            {editing && (
              <>
                <Button
                  type='warning'
                  onClick={() =>
                    isNew
                      ? history.push(RouteUrl.Seccionales)
                      : setEditing(false)
                  }
                >
                  Cancelar
                </Button>
                <Button type='success' onClick={() => form.submit()}>
                  Grabar
                </Button>
              </>
            )}
          </Space>
        }
      >
        <Form
          form={form}
          name='quickForm'
          layout='vertical'
          autoComplete='off'
          disabled={!editing}
          onFinish={onFinish}
        >
          <Form.Item name='seccionalId' hidden />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className='gutter-row' span={8}>
              <Form.Item
                label='Seccional'
                name='nombre'
                rules={[{ required: true, message: 'Falta el nombre' }]}
              >
                <InputExt
                  placeholder='nombre'
                  // style={{ width: '50%' }}
                  maxLength={50}
                  showCount
                  readOnly={!editing}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item
                label='Codigo'
                name='codigo'
                required
                rules={[{ required: true, message: 'Falta el codigo' }]}
              >
                <InputExt
                  placeholder='Codigo'
                  maxLength={50}
                  showCount
                  readOnly={!editing}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Spin>
  );
};

export default SeccionalForm;
