import { Card, Col, Form, Row, Space } from 'antd';
import React from 'react';
import { InputExt } from '../../../components/forms/InputExt';
import { SelectProvincia } from '../../../components/lookups/enumLookups';

export interface Properties {
  editing?: boolean;
}

export const CardDomicilio: React.FC<Properties> = ({ editing }) => {
  return (
    <Card title='Domicilio'>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className='gutter-row' xs={24} md={12} lg={12} xl={10}>
          <Form.Item label='Calle' name={['persona', 'calle']}>
            <InputExt
              placeholder=''
              maxLength={100}
              showCount
              readOnly={!editing}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} md={12} lg={12} xl={2}>
          <Form.Item label='altura' name={['persona', 'altura']}>
            <InputExt
              placeholder=''
              maxLength={20}
              showCount
              readOnly={!editing}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} md={12} lg={12} xl={2}>
          <Form.Item label='Piso' name={['persona', 'piso']}>
            <InputExt
              placeholder='Nombre/s'
              maxLength={10}
              showCount
              readOnly={!editing}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} md={12} lg={12} xl={2}>
          <Form.Item label='Departamento' name={['persona', 'departamento']}>
            <InputExt
              placeholder=''
              maxLength={10}
              showCount
              readOnly={!editing}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} md={12} lg={12} xl={8}>
          <Form.Item label='Entre calles' name={['persona', 'entreCalles']}>
            <InputExt
              placeholder=''
              maxLength={100}
              showCount
              readOnly={!editing}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} md={12} lg={12} xl={8}>
          <Form.Item label='Cód. Postal / Localidad'>
            <Space.Compact>
              <Form.Item
                name={['persona', 'codigoPostal']}
                style={{ width: '30%' }}
              >
                <InputExt maxLength={5} showCount readOnly={!editing} />
              </Form.Item>
              <Form.Item
                name={['persona', 'localidad']}
                style={{ width: '70%' }}
              >
                <InputExt maxLength={100} showCount readOnly={!editing} />
              </Form.Item>
            </Space.Compact>
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} md={12} lg={12} xl={8}>
          <Form.Item label='Barrio' name={['persona', 'barrio']}>
            <InputExt maxLength={100} showCount readOnly={!editing} />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} md={12} lg={12} xl={8}>
          <Form.Item label='Provincia' name={['persona', 'provincia']}>
            <SelectProvincia readOnly={!editing} />
          </Form.Item>
          <Form.Item name={['persona', 'provincia']} hidden />
        </Col>
      </Row>
    </Card>
  );
};
