import type { PageResult } from '../../../models/core/PageResult';
import type { Usuario } from '../../../models/core/Usuario';
import {
  Card,
  Col,
  Input,
  Pagination,
  Row,
  Space,
  Spin,
  Table,
  Tag,
} from 'antd';
import Button from 'antd-button-color';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useState } from 'react';
import { RouteUrl } from '../../../constants/RouteConstants';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { UsuarioService } from '../../../services/core/UsuarioService';
import { configApp } from '../../../services/configApp';
import { useHistory } from 'react-router';


export interface UsuariosProps {}

const UsuarioList: React.FC<UsuariosProps> = () => {
  const history = useHistory();
  const [pageResult, setPageResult] = useState<PageResult<Usuario>>();
  const [page, setPage] = useState<number>(1);
  const [buscar, setBuscar] = useState<string>('');

  const columns: ColumnsType<Usuario> = [
    {
      title: 'Usuario',
      key: 'usuario',
      width: 300,
      render: (_, row) => (
        <a href={`${RouteUrl.Usuario}/${row.usuarioId}`}>{row.username}</a>
      ),
    },
    {
      title: 'Apellido/s y nombre/s',
      key: 'apenom',
      render: (_, row) => <span>{`${row.apellido}, ${row.nombre}`}</span>,
    },
    {
      title: '',
      key: 'status',
      render: (_, row) => (
        <Tag color={row.activo ? 'green' : 'volcano'}>
          {row.activo ? 'Activo' : 'Inactivo'}
        </Tag>
      ),
    },
  ];

  const cargarDatos = useCallback(async (): Promise<void> => {
    const result = await UsuarioService.listar(
      buscar,
      page,
      configApp.ROW_PER_PAGE
    );
    if (result) {
      setPageResult(result);
    }
  }, [buscar, page]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, []);

  function handlerChangePage(page: number, pageSize: number): void {
    setPage(page);
  }

  return (
    <Card
      title='Usuarios'
      extra={
        <Space>
          <Button type='warning' onClick={() => history.push(RouteUrl.Usuario)}>
            Crear nuevo
          </Button>
        </Space>
      }
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Input
            placeholder='Buscar'
            value={buscar}
            onChange={(e: any) => setBuscar(e.target.value)}
          />
        </Col>

        <Col span={24}>
          <Spin spinning={loader.loading} delay={500}>
            <Table
              columns={columns}
              dataSource={pageResult?.data}
              size='small'
              pagination={false}
            />
            {pageResult?.pagination && (
              <Pagination
                size='small'
                current={pageResult.pagination.page}
                total={pageResult?.pagination.rows}
                pageSize={pageResult?.pagination.count}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} de ${total} items`
                }
                onChange={handlerChangePage}
              />
            )}
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};

export default UsuarioList;
