import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import React from 'react';
import dayjs from 'dayjs';

export interface Properties {
  style?: React.CSSProperties;
  value?: Date;
  disabled?: boolean;
  onChange?: (value: Date) => void;
}

export const DatePickerExt: React.FC<Properties> = (props) => {
  const { disabled, value, style } = props;
  const dateFormat = 'DD/MM/YYYY';

  const onChange = (value: any, dateString: string): void => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <DatePicker
      style={{ width: '100%', ...style }}
      value={value ? dayjs(value) : undefined}
      locale={locale}
      disabled={disabled}
      format={dateFormat}
      onChange={onChange}
    />
  );
};
