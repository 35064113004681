import { Empresa } from "./Empresa";
import { LugarTrabajo } from "./LugarTrabajo";
import { Persona } from "./Persona";
export enum LaboralEstado {
    Borrado=0,
    Activo=1,  
    Comisionado=2,
    Baja=3
  }

export interface Laboral {
    laboralId?: number;
    personaId?: number;
    persona?: Persona;
    empresa?: Empresa;
    lugarTrabajo?: LugarTrabajo;
    otroLugar?: string;
    legajo?: string;
    fechaIngresoDireccion?: Date;
    fechaIngreso?: Date;
    fechaEgreso?: Date;
    observaciones?: string;
    estado?: LaboralEstado;
  }
export interface LaboralBaja {
    laboralId?: number;
    fechaEgreso?: string;
    estado?: LaboralEstado;
}
  