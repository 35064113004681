import React from 'react';
import { Input, InputProps } from 'antd';

export const InputExt: React.FC<InputProps> = (props) => {
  const { readOnly, style, showCount, placeholder, disabled } = props;

  const styleExt = style ?? {};
  const editMode = !readOnly && !disabled;
  // if (readOnly) {
  //   styleExt.backgroundColor = "#F0F0F0";
  // }

  return (
    <Input
      {...props}
      // style={styleExt}
      showCount={showCount && editMode}
      placeholder={editMode ? placeholder : ''}
    />
  );
};
