import {
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Switch,
  message,
} from 'antd';
import Button from 'antd-button-color';
import React, { useCallback, useState } from 'react';
import { InputExt } from '../../../components/forms/InputExt';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { Empresa } from '../../../models/gremial/Empresa';
import { EmpresaService } from '../../../services/gremial/EmpresaService';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { RouteUrl } from '../../../constants/RouteConstants';
import { useHistory } from 'react-router';
import { AuthorizationHelper } from '../../../helpers/AuthorizationHelper';
import { Permisos } from '../../../helpers/Permisos';

export interface EmpresaProps {}

const EmpresaForm: React.FC<EmpresaProps> = () => {
  const [editing, setEditing] = useState<boolean>(false);
  const [form] = Form.useForm<Empresa>();
  const [isNew, setIsNew] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const history = useHistory();
  const auth = new AuthorizationHelper();

  const cargarDatos = useCallback(async (): Promise<void> => {
    const urlSection = window.location.pathname.split('/');
    const id = urlSection[3];
    const result = await EmpresaService.get(id);
    if (result) {
      form.setFieldsValue(result);
    } else {
      setEditing(true);
      setIsNew(true);
    }
  }, [form]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, []);

  async function onFinish(values: Empresa): Promise<void> {
    const result = await EmpresaService.update(values);
    console.log(values);
    if (result) {
      messageApi.open({
        type: 'success',
        content: 'Guardado exitoso',
      });
      setEditing(false);
    } else {
      messageApi.open({
        type: 'error',
        content: 'Se produjo un error al guardar',
      });
    }
  }
  const canEmpresas = auth.canAccess(Permisos.sog_empresas);

  return (
    <Spin spinning={loader.loading} delay={500}>
      {contextHolder}
      <Card
        title='Ficha de Empresa'
        extra={
          <Space>
            {canEmpresas && !editing && (
              <>
                <Button type='warning' onClick={() => setEditing(true)}>
                  Editar
                </Button>
              </>
            )}
            {editing && (
              <>
                <Button
                  type='warning'
                  onClick={() =>
                    isNew ? history.push(RouteUrl.Empresas) : setEditing(false)
                  }
                >
                  Cancelar
                </Button>
                <Button type='success' onClick={() => form.submit()}>
                  Grabar
                </Button>
              </>
            )}
          </Space>
        }
      >
        <Form
          form={form}
          name='quickForm'
          layout='vertical'
          autoComplete='off'
          disabled={!editing}
          onFinish={onFinish}
        >
          <Form.Item name='empresaId' hidden />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className='gutter-row' span={6}>
              <Form.Item
                label='Codigo'
                name='codigo'
                rules={[{ required: true, message: 'Falta el codigo' }]}
              >
                <InputExt
                  placeholder='codigo'
                  //style={{ width: '50%' }}
                  maxLength={5}
                  showCount
                  readOnly={!editing}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={12}>
              <Form.Item
                label='Empresa'
                name='nombre'
                rules={[{ required: true, message: 'Falta el nombre' }]}
              >
                <InputExt
                  placeholder='nombre'
                  // style={{ width: '50%' }}
                  maxLength={50}
                  showCount
                  readOnly={!editing}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={6}>
              <Form.Item
                label='CUIT'
                name='cuit'
                required
                rules={[{ required: true, message: 'Falta el cuit' }]}
              >
                <InputExt
                  placeholder='CUIT'
                  maxLength={50}
                  showCount
                  readOnly={!editing}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.List name='lugaresTrabajo'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align='baseline'
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'first']}
                      rules={[{ required: true, message: 'Fala codigo' }]}
                    >
                      <Input placeholder='Codigo' />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'last']}
                      rules={[{ required: true, message: 'Falta nombre' }]}
                    >
                      <Input placeholder='nombre' />
                    </Form.Item>
                    {editing && (
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    )}
                  </Space>
                ))}
                <Form.Item>
                  <Divider orientation='left'>Lugar de trabajo</Divider>
                  {editing && (
                    <Button
                      type='dashed'
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Agregar Lugar de trabajo
                    </Button>
                  )}
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Card>
    </Spin>
  );
};

export default EmpresaForm;
