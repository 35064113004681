import type { PageResult } from '../../../models/core/PageResult';

import { Card, Col, Input, Pagination, Row, Space, Spin, Table } from 'antd';
import Button from 'antd-button-color';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useState } from 'react';
import { RouteUrl } from '../../../constants/RouteConstants';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { Seccional } from '../../../models/gremial/Seccional';
import { configApp } from '../../../services/configApp';
import {
  SeccionalFilters,
  SeccionalService,
} from '../../../services/gremial/SeccionalService';
import { useHistory } from 'react-router';
import { AuthorizationHelper } from '../../../helpers/AuthorizationHelper';
import { Permisos } from '../../../helpers/Permisos';

export interface SeccionalProps {}

const SeccionalList: React.FC<SeccionalProps> = () => {
  const [pageResult, setPageResult] = useState<PageResult<Seccional>>();
  const [page, setPage] = useState<number>(1);
  const [buscar, setBuscar] = useState<string>('');
  const history = useHistory();
  const auth = new AuthorizationHelper();

  const columns: ColumnsType<Seccional> = [
    {
      title: 'Seccional',
      key: 'seccional',
      width: 600,
      render: (_, row) => (
        <a href={`${RouteUrl.Seccional}/${row.seccionalId}`}>{row.nombre}</a>
      ),
    },
    {
      title: 'Código',
      key: 'codigo',
      render: (_, row) => <span>{row.codigo}</span>,
    },
  ];
  const cargarDatos = useCallback(async (): Promise<void> => {
    const filters: SeccionalFilters = {
      buscar,
      page: page,
      count: configApp.ROW_PER_PAGE,
    };

    const result = await SeccionalService.listar(filters);
    console.log(result);
    if (result) {
      setPageResult(result);
    }
  }, [buscar, page]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, [page, buscar]);

  function handlerChangePage(page: number, pageSize: number): void {
    setPage(page);
  }

  const canSeccionales = auth.canAccess(Permisos.sog_seccionales);

  return (
    <Card
      title='Seccionales'
      extra={
        <Space>
          {canSeccionales && (
            <Button
              type='warning'
              onClick={() => history.push(RouteUrl.Seccional)}
            >
              Crear nuevo
            </Button>
          )}
        </Space>
      }
    >
      <Row>
        <Col xs={24} sm={24} md={16}>
          <Input
            placeholder='Buscar'
            value={buscar}
            onChange={(e: any) => setBuscar(e.target.value)}
          />
        </Col>
      </Row>

      <Spin spinning={loader.loading} delay={500}>
        <Table
          rowKey='seccionalId'
          columns={columns}
          dataSource={pageResult?.data}
          size='small'
          pagination={false}
        />
        {pageResult?.pagination && (
          <Pagination
            size='small'
            current={pageResult.pagination.page}
            total={pageResult?.pagination.rows}
            pageSize={pageResult?.pagination.count}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} de ${total} items`
            }
            onChange={handlerChangePage}
          />
        )}
      </Spin>
    </Card>
  );
};

export default SeccionalList;
