import { Card, Skeleton } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useAsyncCall } from "../../../../hooks/useAsyncCall";
import { FamiliarService } from "../../../../services/gremial/FamiliarService";
import { CardFamiliar } from "./CardFamiliar";
import { Familiar } from "../../../../models/gremial/Familiar";
import { CardFamiliarModal } from "./CardFamiliaresModal";
import "./CardFamiliares.css";
import { AuthorizationHelper } from "../../../../helpers/AuthorizationHelper";
import { Permisos } from "../../../../helpers/Permisos";

export interface Properties {
  personaId: number;
}

export const CardFamiliares: React.FC<Properties> = ({ personaId }) => {
  const [familiares, setFamiliares] = useState<Familiar[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFamiliar, setSelectedFamiliar] = useState<Familiar>({});
  const auth = new AuthorizationHelper();

  const loadData = useCallback(async (): Promise<void> => {
    const result = await FamiliarService.listar(personaId.toString());
    if (result) {
      setFamiliares(result);
    } else {
    }
  }, [personaId]);

  const loader = useAsyncCall(async () => {
    await loadData();
  }, []);

  const handleNew = () => {
    setSelectedFamiliar({});
    setIsModalOpen(true);
  };

  const handleEdit = (familiar: Familiar) => {
    setSelectedFamiliar(familiar);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = useCallback(
    (familiar: Familiar) => {
      const list = familiares;
      const idx = list?.findIndex((x) => x.familiarId === familiar.familiarId);
      if (list && idx) {
        if (idx > -1) {
          list[idx] = familiar;
        }
      }

      // const list = familiares?.map((x) => {
      //   if (x.familiarId === familiar.familiarId) {
      //     return familiar;
      //   }
      //   return x;
      // });

      setFamiliares(list);
      setIsModalOpen(false);
    },
    [familiares]
  );

  const canAfiliados = auth.canAccess(Permisos.sog_afiliados);

  return (
    <Skeleton loading={loader.loading} active>
      <div className="card-familiares">
        {familiares &&
          familiares?.map((item) => (
            <CardFamiliar
              key={item.familiarId}
              familiar={item}
              onEdit={handleEdit}
            />
          ))}

        {canAfiliados && (
          <Card className="CardNew" onClick={handleNew}>
            <i className="far fa-plus-circle fa-3x"></i>
          </Card>
        )}
      </div>
      <CardFamiliarModal
        familiar={selectedFamiliar}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onChange={handleChange}
        personaId={personaId}
      />
    </Skeleton>
  );
};
