export class LocalStorageService {
  static get = <T>(key: string, defaultValue: T | undefined = undefined, silent = true): T | undefined => {
    const value: string = localStorage[key] || defaultValue;

    // if silent = false throw error
    if (!silent && value === undefined) {
      throw `Key '${key}' not found in localStorage`;
    }

    return value !== undefined ? (JSON.parse(value) as T) : undefined;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static set = (key: string, value: any): void => {
    localStorage[key] = JSON.stringify(value);
  };

  static remove = (key: string): void => {
    return localStorage.removeItem(key);
  };

  static clear = (): void => {
    localStorage.clear();
  };
}
