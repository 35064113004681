import type { PageResult } from '../../../models/core/PageResult';
import {
  Card,
  Col,
  Input,
  Pagination,
  Row,
  Space,
  Spin,
  Table,
  Tag,
} from 'antd';
import Button from 'antd-button-color';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useState } from 'react';
import { RouteUrl } from '../../../constants/RouteConstants';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { Rubro } from '../../../models/sas/Rubro';
import {
  RubrosFilters,
  RubrosService,
} from '../../../services/accionSocial/RubrosService';
import Tree, { DataNode, EventDataNode } from 'antd/es/tree';
import { DownOutlined } from '@ant-design/icons';
import { RubroItem } from '../../../models/sas/RubroItem';
import DirectoryTree from 'antd/es/tree/DirectoryTree';

export interface RubrosProps {}

const RubrosList: React.FC<RubrosProps> = () => {
  const [pageResult, setPageResult] = useState<PageResult<Rubro>>();
  const [currentItems, setItems] = useState<RubroItem[]>();

  const cargarDatos = useCallback(async (): Promise<void> => {
    const filters: RubrosFilters = {
      showSubRubros: true,
      showItems: true,
      soloActivos: true,
    };
    const result = await RubrosService.listar(filters);
    if (result) {
      setPageResult(result);
    }
  }, []);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, []);
  const columns: ColumnsType<RubroItem> = [
    {
      title: 'Codigo',
      key: 'codigo',
      render: (_, row) => <span>{row.codigo}</span>,
    },
    {
      title: 'Descripcion',
      key: 'descripcion',
      render: (_, row) => <span>{row.descripcion}</span>,
    },
    {
      title: 'Reintegro',
      key: 'reintegro',
      render: (_, row) => <span>{row.reintegro}</span>,
    },
  ];

  const handleClick = (items: RubroItem[]) => {
    if (items) {
      setItems(items);
    }
  };

  const convertToTreeData = (rubros: Rubro[]): DataNode[] => {
    return rubros.map<DataNode>((rubro) => ({
      key: rubro.rubroId,
      title: (
        <div
          style={{ display: 'contents' }}
          onClick={() => handleClick(rubro.items ?? [])}
        >
          {rubro.nombre || 'No Especificado'}
          <i className='fas fa-search' />
        </div>
      ),
      children:
        rubro.subRubros?.map((subRubro) => ({
          key: rubro.rubroId + '.' + subRubro.subRubroId,
          title: (
            <div
              style={{ display: 'contents' }}
              onClick={() => handleClick(rubro.items ?? [])}
            >
              {subRubro.nombre || 'No Especificado'}
            </div>
          ),
        })) || [],
    }));
  };

  const treeData: DataNode[] = convertToTreeData(pageResult?.data ?? []);

  return (
    <Card
      title='Rubros'
      extra={
        <Space>
          <Button type='warning'>Crear nuevo</Button>
        </Space>
      }
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col md={10} lg={8}>
          <Card>
            <DirectoryTree
              multiple
              showIcon
              showLine
              switcherIcon={<DownOutlined />}
              treeData={treeData}
            />
          </Card>
        </Col>
        <Col md={16} lg={16}>
          {currentItems && (
            <Card title='Items'>
              <Spin spinning={loader.loading} delay={500}>
                <Table
                  columns={columns}
                  dataSource={currentItems}
                  size='small'
                  pagination={false}
                />
              </Spin>
            </Card>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default RubrosList;
