import { SelectItem } from '../components/forms/SelectExt';
import { Dictionary } from './Collections';

export class EnumHelper {
  static existValue(type: any, value: any): boolean {
    return (
      Object.keys(type)
        .filter((k) => isNaN(Number(k)))
        .filter((k) => type[k] === value).length > 0
    );
  }

  static toSelect(type: any): SelectItem[] {
    let keys = Object.keys(type);
    keys = keys.splice(0, keys.length / 2);

    const list: SelectItem[] = keys.map((x) => {
      const label = type[x];
      const value = parseInt(x);
      return { value, label };
    });
    return list;
  }
}

export class ArrayHelper {
  static sortByProperties<T>(array: T[], ...properties: (keyof T)[]): void {
    array.sort((a: any, b: any) => {
      let i = 0;
      while (i < properties.length) {
        a = a[properties[i]];
        b = b[properties[i]];
        i++;
      }
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  static convertToDictionary<T extends any>(
    array: Array<T>,
    indexKey: keyof T,
    valueKey?: keyof T
  ): Dictionary<T>;
  static convertToDictionary<T extends any>(
    array: Array<T>,
    indexFunction: (obj: T) => string,
    valueFunction?: (obj: T) => any
  ): Dictionary<T>;
  static convertToDictionary<T extends any, K>(
    array: Array<T>,
    key: keyof T | ((obj: T) => string),
    value: keyof T | ((obj: T) => any) | undefined
  ): Dictionary<T> {
    const dictionary: Dictionary<T> = {};
    for (let i = 0; i < array.length; i++) {
      let keyValue: any;
      if (typeof key === 'function') {
        keyValue = key(array[i]);
      } else {
        keyValue = array[i][key];
      }
      let valueValue: any;
      if (typeof value === 'function') {
        valueValue = value(array[i]);
      } else if (typeof value === 'undefined') {
        valueValue = array[i];
      } else {
        valueValue = array[i][value];
      }
      dictionary[keyValue] = valueValue;
    }
    return dictionary;
  }

  static convertToArray<T>(dict: Dictionary<T>): T[] {
    if (!dict) {
      return [];
    } else {
      return Object.values(dict).filter((v) => v !== undefined) as T[];
    }
  }
}

export const setUrlParam = (param: string, value: string): void => {
  if (window.history.pushState) {
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set(param, value);
    const newUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      currentUrlParams.toString();
    window.history.pushState({ path: newUrl }, '', newUrl);
  }
};

export class ObjectPathsHelper {
  static types = {
    null: '[object Null]',
    object: '[object Object]',
    array: '[object Array]',
    string: '[object String]',
    boolean: '[object Boolean]',
    number: '[object Number]',
    date: '[object Date]',
  };

  /** Returns a list of updates from a patch object to process into Dynamo.
   * Empty objects are not allowed at any point and will throw an exception.*/
  static getPathValues(jsonObject: object): { [path: string]: string } {
    return this.getPathValuesInternal(jsonObject);
  }

  private static getPathValuesInternal(
    jsonObject: object,
    basePath = '',
    pathValues: { [path: string]: string } = {}
  ): { [path: string]: string } {
    ObjectPathsHelper.checkObjectIsNotEmpty(jsonObject, basePath);

    // Navigate through patch object properties
    jsonObject &&
      Object.entries(jsonObject).forEach(([key, value]) => {
        // Define path for current item
        let currentPath = basePath;
        if (currentPath != '') {
          currentPath += '.';
        }
        currentPath += `${key}`;

        // Recursively navigate through values if element is not native and the original object value exists
        if (ObjectPathsHelper.isNative(value)) {
          ObjectPathsHelper.checkObjectIsNotEmpty(value, currentPath);
          if (Object.prototype.toString.call(value) == this.types.array) {
            const array = value as Array<any>;
            array.forEach((element) => {
              pathValues = ObjectPathsHelper.getPathValuesInternal(
                element,
                currentPath,
                pathValues
              );
            });
          } else {
            pathValues[currentPath] = value;
            console.debug(`Path: ${currentPath} - Value: ${value}`);
          }
        } else {
          pathValues = ObjectPathsHelper.getPathValuesInternal(
            value,
            currentPath,
            pathValues
          );
        }
      });

    return pathValues;
  }

  private static checkObjectIsNotEmpty(
    patchObject: object,
    basePath: string
  ): void {
    if (ObjectPathsHelper.isEmptyObject(patchObject)) {
      console.log(
        `Patch object cannot include an empty object (path: ${basePath}).`
      );
    }
  }

  private static isEmptyObject(obj: object): boolean {
    return (
      !!obj && obj.constructor === Object && Object.entries(obj).length === 0
    );
  }

  //TODO: Improve native type checking
  private static isNative(obj: any): boolean {
    return (
      Object.prototype.toString.call(obj) == this.types.array ||
      Object.prototype.toString.call(obj) == this.types.boolean ||
      Object.prototype.toString.call(obj) == this.types.date ||
      Object.prototype.toString.call(obj) == this.types.number ||
      Object.prototype.toString.call(obj) == this.types.null ||
      Object.prototype.toString.call(obj) == this.types.string
    );
  }
}
