import { EnumHelper } from '../../helpers/UtilsHelper';
import {
  DocumentoTipo,
  EstadoCivil,
  Genero,
  Nacionalidad,
  Provincia,
  RelacionFamiliar,
} from '../../models/Enums';
import { SelectExt, SelectExtProps, SelectItem } from '../forms/SelectExt';

export const SelectProvincia = (props: SelectExtProps): JSX.Element => {
  const options: SelectItem[] = EnumHelper.toSelect(Provincia);
  return <SelectExt {...props} options={options} placeholder='Provincia' />;
};

export const SelectDocumentoTipo = (props: SelectExtProps): JSX.Element => {
  const options: SelectItem[] = EnumHelper.toSelect(DocumentoTipo);
  return <SelectExt style={{ width: '100px' }} {...props} options={options} />;
};

export const SelectGenero = (props: SelectExtProps): JSX.Element => {
  const options: SelectItem[] = EnumHelper.toSelect(Genero);
  return <SelectExt {...props} options={options} placeholder='Genero' />;
};

export const SelectEstadoCivil = (props: SelectExtProps): JSX.Element => {
  const options: SelectItem[] = EnumHelper.toSelect(EstadoCivil);
  return <SelectExt {...props} options={options} placeholder='Estado Civil' />;
};

export const SelectRelacionFamiliar = (props: SelectExtProps): JSX.Element => {
  const options: SelectItem[] = EnumHelper.toSelect(RelacionFamiliar);
  return (
    <SelectExt {...props} options={options} placeholder='Relacion Familiar' />
  );
};

export const SelectNacionalidad = (props: SelectExtProps): JSX.Element => {
  const options: SelectItem[] = EnumHelper.toSelect(Nacionalidad);
  return <SelectExt {...props} options={options} placeholder='Nacionalidad' />;
};
