export const configApp = {
  // DEV
  //apiUrl: 'http://192.168.0.182:6000/api',
  // apiUrl: 'https://localhost:5000/api',
  // QA
  //apiUrl: "http://192.168.0.95:8200/api",
  // PROD
  apiUrl: 'http://apdfaonline.ddns.net:8200/api',

  ROW_PER_PAGE: 15,
};
