import { Col, Form, Modal, Row, Space, message } from 'antd';
import React, { useEffect } from 'react';

import { InputExt } from '../../../../components/forms/InputExt';
import { Familiar } from '../../../../models/gremial/Familiar';
import { FamiliarService } from '../../../../services/gremial/FamiliarService';
import {
  SelectDocumentoTipo,
  SelectGenero,
  SelectNacionalidad,
  SelectRelacionFamiliar,
} from '../../../../components/lookups/enumLookups';
import { InputNumberExt } from '../../../../components/forms/InputNumberExt';
import { DatePickerExt } from '../../../../components/forms/DatePickerExt';
export interface Properties {
  familiar: Familiar;
  isOpen: boolean;
  onClose: () => void;
  onChange?: (familiar: Familiar) => void;
  personaId: number;
}

export const CardFamiliarModal: React.FC<Properties> = ({
  familiar,
  isOpen,
  onClose,
  onChange,
  personaId: persona,
}) => {
  const [form] = Form.useForm<Familiar>();
  const [messageApi, contextHolder] = message.useMessage();

  form.setFieldsValue(familiar);

  const handleOk = () => {
    try {
      form.submit();
    } catch (error) {}
  };
  async function onFinish(values: Familiar): Promise<void> {
    values.personaId = persona ?? 0;
    console.log(values.familiarId);
    const result = await FamiliarService.update(values);
    console.log(values);
    if (result) {
      messageApi.open({
        type: 'success',
        content: 'Guardado exitoso',
      });
      onChange?.(values);
    } else {
      messageApi.open({
        type: 'error',
        content: 'Se produjo un error al guardar',
      });
    }
    onClose();
  }
  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      title='Editar Familiar'
      width={'500px'}
      open={isOpen}
      okText='Grabar'
      cancelText='Cancelar'
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        name='editFamilar'
        layout='vertical'
        autoComplete='off'
        onFinish={onFinish}
        disabled={false}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Form.Item name='familiarId' hidden />
          <Col className='gutter-row' span={12}>
            <Form.Item label='Documento'>
              <Space.Compact>
                <Form.Item name='documentoTipo'>
                  <SelectDocumentoTipo />
                </Form.Item>
                <Form.Item
                  name='documentoNumero'
                  rules={[{ required: true, message: 'Campo requerido' }]}
                >
                  <InputNumberExt style={{ width: '100%' }} />
                </Form.Item>
              </Space.Compact>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={12}>
            <Form.Item label='Relacion' name='relacionTipo'>
              <SelectRelacionFamiliar />
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={12}>
            <Form.Item
              label='Apellido/s'
              name='apellido'
              rules={[{ required: true, message: 'Falta el apellido' }]}
            >
              <InputExt placeholder='Apellido/s' maxLength={50} showCount />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={12}>
            <Form.Item
              label='Nombre/s'
              name='nombre'
              rules={[{ required: true, message: 'Falta el apellido' }]}
            >
              <InputExt placeholder='Nombre/s' maxLength={50} showCount />
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={12}>
            <Form.Item label='Fecha de nacimiento' name='fechaNacimiento'>
              <DatePickerExt />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={12}>
            <Form.Item label='Genero' name='genero'>
              <SelectGenero />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={12}>
            <Form.Item label='Nacionalidad' name='nacionalidad'>
              <SelectNacionalidad style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={12}>
            <Form.Item label='Lugar Nacimiento' name='lugarNacimiento'>
              <InputExt placeholder='' maxLength={50} showCount />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
