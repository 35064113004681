import { Card, Col, Form, Row } from 'antd';
import React from 'react';
import { InputExt } from '../../../components/forms/InputExt';

export interface Properties {
  editing?: boolean;
}

export const CardContacto: React.FC<Properties> = ({ editing }) => {
  return (
    <Card title='Contacto'>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className='gutter-row' xs={24} md={12} xl={6}>
          <Form.Item label='Telefono' name={['persona', 'telefono1']}>
            <InputExt
              placeholder=''
              maxLength={50}
              showCount
              readOnly={!editing}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} md={12} xl={6}>
          <Form.Item label='Telefono 2' name={['persona', 'telefono2']}>
            <InputExt maxLength={50} showCount readOnly={!editing} />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} md={12} xl={6}>
          <Form.Item label='Correo electronico' name={['persona', 'email']}>
            <InputExt maxLength={50} showCount readOnly={!editing} />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} md={12} xl={6}>
          <Form.Item label='Telefono Movil' name={['persona', 'celular']}>
            <InputExt maxLength={50} showCount readOnly={!editing} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};
