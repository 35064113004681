import React, { useState, useCallback, useEffect } from "react";
import { PageResult } from "../../models/core/PageResult";
import { Afiliado, AfiliadoListModel } from "../../models/gremial/Afiliado";
import {
  AfiliadoFilters,
  AfiliadoService,
} from "../../services/gremial/AfiliadoService";
import Table, { ColumnsType } from "antd/es/table";

import { Col, Input, Modal, Pagination, Row, Space, Spin, Tag } from "antd";
import { useAsyncCall } from "../../hooks/useAsyncCall";
import Button from "antd-button-color";
const { Search } = Input;

interface properties {
  editable?: boolean;
  setShowSelectBeneficiario(estado: boolean): void;
  setSelectedPersonaId(personaId: number): void;
}
export const LookupAfiliado = (props: properties): JSX.Element => {
  const isEditable = props.editable ?? false;
  const [isModalOpen, setModal] = useState(false);
  const [pageResult, setPageResult] = useState<PageResult<AfiliadoListModel>>({
    data: [],
    pagination: { page: 0, rows: 0, count: 15 },
  });
  const [model, setModel] = useState<AfiliadoListModel>({});
  const [page, setPage] = useState<number>(1);
  const [buscar, setBuscar] = useState<string>("");

  function onClickModal(): void {
    setModal(!isModalOpen);
  }

  const cargarDatos = useCallback(async (): Promise<void> => {
    const filters: AfiliadoFilters = {
      buscar,
      page: page,
      count: 15,
    };

    const result = await AfiliadoService.listar(filters);
    console.log(result);
    if (result) {
      setPageResult(result);
    }
  }, [buscar, page]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, [page, buscar]);

  function handlerChangePage(page: number, pageSize: number): void {
    setPage(page);
  }

  const columns: ColumnsType<AfiliadoListModel> = [
    {
      title: "Afiliado",
      key: "afiliado",
      render: (_, row) => <span>{row.socio}</span>,
    },
    {
      title: "Legajo",
      key: "legajo",
      render: (_, row) => <span>{row.legajo}</span>,
    },
    {
      title: "Documento",
      key: "documento",
      render: (_, row) => <span>{row.documento}</span>,
    },
    {
      title: "Apellido/s y nombre/s",
      key: "nombreApellido",
      render: (_, row) => (
        <span>
          {row.apellido}, {row.nombre}
        </span>
      ),
    },
    {
      title: "Estado",
      key: "estado",
      render: (_, row) => <span>{row.estadoNombre}</span>,
    },
    {
      title: "Seccional",
      key: "seccional",
      render: (_, row) => <span>{row.seccionalNombre}</span>,
    },
    {
      title: "Empresa",
      key: "empresa",
      render: (_, row) => <span>{row.empresaNombre}</span>,
    },
  ];
  const onChangePage = async (page: number): Promise<void> => {
    setPage(page);
  };
  const onChangeSize = async (page: number): Promise<void> => {
    setPage(page);
  };
  function onCellClick(afiliado: AfiliadoListModel): void {
    setModel(afiliado);
    setBuscar("");
    props.setSelectedPersonaId(afiliado.personaId ?? 0);
    props.setShowSelectBeneficiario(true);
    console.log(afiliado.personaId);
    handleCancel();
  }
  const handleCancel = () => {
    setModal(false);
  };
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <Search
            placeholder="Afiliado"
            value={model.socio}
            readOnly={true}
            onSearch={onClickModal}
          />
        </Col>
        <Col span={12}>
          <Input
            placeholder="Nombre/s"
            value={`${model.nombre ?? ""} ${model.apellido ?? ""}`}
            readOnly={true}
          />
        </Col>
        <Col span={6}>
          <Input
            placeholder="Documento"
            value={model.documento}
            readOnly={true}
          />
        </Col>
      </Row>

      <Modal
        title="Seleccionar Afiliado"
        width={1200}
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Spin spinning={loader.loading} delay={500}>
          <Input
            placeholder="Buscar"
            value={buscar}
            onChange={(e: any) => setBuscar(e.target.value)}
          />
          <Table
            columns={columns}
            dataSource={pageResult?.data}
            size="small"
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  onCellClick(record);
                },
              };
            }}
          />
          {pageResult?.pagination && (
            <Pagination
              size="small"
              current={pageResult.pagination.page}
              total={pageResult?.pagination.rows}
              pageSize={pageResult?.pagination.count}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} de ${total} items`
              }
              onChange={handlerChangePage}
            />
          )}
        </Spin>
      </Modal>
    </>
  );
};
