import {
  Card,
  Col,
  Form,
  Row,
  Space,
  Spin,
  Switch,
  Transfer,
  message,
} from 'antd';
import Button from 'antd-button-color';
import { TransferDirection } from 'antd/es/transfer';
import React, { useCallback, useState } from 'react';

import { InputExt } from '../../../components/forms/InputExt';
import { RouteUrl } from '../../../constants/RouteConstants';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { Rol } from '../../../models/core/Rol';
import type { Usuario } from '../../../models/core/Usuario';
import { RolService } from '../../../services/core/RolService';
import { UsuarioService } from '../../../services/core/UsuarioService';
import { useHistory } from 'react-router';

export interface UsuariosProps {}

const UsuarioForm: React.FC<UsuariosProps> = () => {
  const history = useHistory();
  const [editing, setEditing] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [form] = Form.useForm<Usuario>();
  const [items, setItems] = useState<Rol[]>([]);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const [messageApi, contextHolder] = message.useMessage();

  const cargarDatos = useCallback(async (): Promise<void> => {
    const urlSection = window.location.pathname.split('/');
    const id = urlSection[3];

    const roles = await RolService.listar();
    setItems(roles?.data ?? []);
    if (id) {
      const result = await UsuarioService.get(id);
      if (result) {
        form.setFieldsValue(result);
        setTargetKeys(result.roles ?? []);
      }
    } else {
      setEditing(true);
      setIsNew(true);
    }
  }, [form]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, []);

  const onChange = (
    nextTargetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  async function onFinish(values: Usuario): Promise<void> {
    const usuario: Usuario = { ...values, roles: targetKeys };
    const result = await UsuarioService.update(usuario);
    console.log(values);
    if (result) {
      messageApi.open({
        type: 'success',
        content: 'Guardado exitoso',
      });
      setEditing(false);
    } else {
      messageApi.open({
        type: 'error',
        content: 'Se produjo un error al guardar',
      });
    }
  }

  return (
    <Spin spinning={loader.loading} delay={500}>
      {contextHolder}
      <Card
        title='Ficha de usuario'
        extra={
          <Space>
            {!editing && (
              <>
                <Button type='warning' onClick={() => setEditing(true)}>
                  Editar
                </Button>
              </>
            )}
            {editing && (
              <>
                <Button
                  type='warning'
                  onClick={() =>
                    isNew ? history.push(RouteUrl.Usuarios) : setEditing(false)
                  }
                >
                  Cancelar
                </Button>
                <Button type='success' onClick={() => form.submit()}>
                  Grabar
                </Button>
              </>
            )}
          </Space>
        }
      >
        <Form
          form={form}
          name='quickForm'
          layout='vertical'
          autoComplete='off'
          disabled={!editing}
          onFinish={onFinish}
        >
          <Form.Item name='usuarioId' hidden />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className='gutter-row' span={8}>
              <Form.Item
                label='Usuario'
                name='username'
                rules={[{ required: true, message: 'Falta el nombre' }]}
              >
                <InputExt
                  placeholder='nombre / email'
                  // style={{ width: '50%' }}
                  maxLength={50}
                  showCount
                  readOnly={!editing}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={8}>
              <Form.Item
                label='Nombre'
                name='nombre'
                required
                rules={[{ required: true, message: 'Falta el nombre' }]}
              >
                <InputExt
                  placeholder='Nombre'
                  maxLength={50}
                  showCount
                  readOnly={!editing}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={8}>
              <Form.Item
                label='Apellido'
                name='apellido'
                required
                rules={[{ required: true, message: 'Falta el apellido' }]}
              >
                <InputExt
                  placeholder='Nombre'
                  maxLength={50}
                  showCount
                  readOnly={!editing}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={8}>
              <Form.Item
                label='Email'
                name='email'
                required
                rules={[{ required: true, message: 'Falta el email' }]}
              >
                <InputExt
                  placeholder='nombre@empresa.com.ar'
                  maxLength={50}
                  showCount
                  readOnly={!editing}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={8}>
              <Form.Item label='Estado' name='activo' valuePropName='checked'>
                <Switch checkedChildren='Activo' unCheckedChildren='Inactivo' />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              Roles
              <Transfer
                disabled={!editing}
                listStyle={{
                  width: '50%',
                  height: 300,
                }}
                dataSource={items}
                showSearch
                filterOption={(input, option) =>
                  (option?.nombre!.toLowerCase() ?? '').includes(
                    input.toLowerCase()
                  )
                }
                titles={['Disponibles', 'Asignados']}
                operations={['Asignar', 'Quitar']}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={onChange}
                onSelectChange={onSelectChange}
                // onScroll={onScroll}
                rowKey={(item: Rol) => item.rolId}
                render={(item: Rol) => item.nombre}
              />
            </Col>
          </Row>
        </Form>
      </Card>
    </Spin>
  );
};

export default UsuarioForm;
