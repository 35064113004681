import { DocumentoTipo, Genero, Nacionalidad, RelacionFamiliar } from "../Enums";
import { Persona } from "./Persona";

export interface Familiar {
    familiarId?: number;
    personaId?: number;
    persona?: Persona;
    relacionTipo ?: RelacionFamiliar;
    estudiante ?: boolean;
    pendienteAprobacion ?: boolean;
    borrado ?: boolean; 

    documentoTipo?: DocumentoTipo;
    documentoNumero?: number;
    documento?: string;
    edad?: number;
    nombre?: string;
    apellido?: string;
    nombres?: string;
    fechaNacimiento?: Date;
    genero?: Genero;
    nacionalidad?: Nacionalidad;
    lugarNacimiento?: string;
  }

  export interface FamiliarIcon {
    icono: string;
    color: Familiarcolores;
  }
  
  export enum Familiarcolores{
    rosa = 0,
    azul = 1,
    gris = 2
  } 

 
  