import { Card, Col, Input, Pagination, Row, Space, Spin, Table } from 'antd';
import Button from 'antd-button-color';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteUrl } from '../../../constants/RouteConstants';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import type { PageResult } from '../../../models/core/PageResult';
import { AfiliadoListModel } from '../../../models/gremial/Afiliado';
import { configApp } from '../../../services/configApp';
import {
  AfiliadoFilters,
  AfiliadoService,
} from '../../../services/gremial/AfiliadoService';
import { AuthorizationHelper } from '../../../helpers/AuthorizationHelper';
import { Permisos } from '../../../helpers/Permisos';

export interface AfiliadoProps {}

const AfiliadoList: React.FC<AfiliadoProps> = () => {
  const history = useHistory();
  const [pageResult, setPageResult] = useState<PageResult<AfiliadoListModel>>();
  const [page, setPage] = useState<number>(1);
  const [buscar, setBuscar] = useState<string>('');
  const auth = new AuthorizationHelper();

  const columns: ColumnsType<AfiliadoListModel> = [
    {
      title: 'Afiliado',
      key: 'afiliado',
      render: (_, row) => (
        <a href={`${RouteUrl.Afiliado}/${row.afiliadoId}`}>{row.socio}</a>
      ),
    },
    {
      title: 'Legajo',
      key: 'legajo',
      render: (_, row) => <span>{row.legajo}</span>,
    },
    {
      title: 'Documento',
      key: 'documento',
      render: (_, row) => <span>{row.documento}</span>,
    },
    {
      title: 'Apellido/s y nombre/s',
      key: 'nombreApellido',
      render: (_, row) => (
        <span>
          {row.apellido}, {row.nombre}
        </span>
      ),
    },
    {
      title: 'Estado',
      key: 'estado',
      render: (_, row) => <span>{row.estadoNombre}</span>,
    },
    {
      title: 'Seccional',
      key: 'seccional',
      render: (_, row) => <span>{row.seccionalNombre}</span>,
    },
    {
      title: 'Empresa',
      key: 'empresa',
      render: (_, row) => <span>{row.empresaNombre}</span>,
    },
  ];
  const cargarDatos = useCallback(async (): Promise<void> => {
    const filters: AfiliadoFilters = {
      buscar,
      page: page,
      count: configApp.ROW_PER_PAGE,
    };

    const result = await AfiliadoService.listar(filters);
    console.log(result);
    if (result) {
      setPageResult(result);
    }
  }, [buscar, page]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, [page, buscar]);

  function handlerChangePage(page: number, pageSize: number): void {
    setPage(page);
  }
  const canAfiliados = auth.canAccess(Permisos.sog_afiliados);
  return (
    <Card
      title='Afiliados'
      extra={
        <Space>
          {canAfiliados && (
            <Button
              type='warning'
              onClick={() => history.push(RouteUrl.Afiliado)}
            >
              Crear nuevo
            </Button>
          )}
        </Space>
      }
    >
      <Row>
        <Col xs={24} sm={24} md={16}>
          <Input
            placeholder='Buscar'
            value={buscar}
            onChange={(e: any) => setBuscar(e.target.value)}
          />
        </Col>
      </Row>

      <Spin spinning={loader.loading} delay={500}>
        <Table
          columns={columns}
          dataSource={pageResult?.data}
          size='small'
          pagination={false}
        />
        {pageResult?.pagination && (
          <Pagination
            size='small'
            current={pageResult.pagination.page}
            total={pageResult?.pagination.rows}
            pageSize={pageResult?.pagination.count}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} de ${total} items`
            }
            onChange={handlerChangePage}
          />
        )}
      </Spin>
    </Card>
  );
};

export default AfiliadoList;
