import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';

export interface InputNumberExtProps extends InputNumberProps {
  decimals?: number;
}

export const InputNumberExt: React.FC<InputNumberExtProps> = (props) => {
  const { readOnly, placeholder, disabled, decimals } = props;

  const editMode = !readOnly && !disabled;
  let mask: RegExp;
  if (decimals) {
    if (decimals === 0) {
      mask = /^-?\d+$/;
      mask = /\B(?=(\d{3})+(?!\d))/g;
    } else {
      mask = /\B(?=(\d{3})+(?!\d))/g;
      //formatter={(value) => `$ ${value}`.replace(mask, ',')}
    }
  }

  return (
    <InputNumber
      {...props}
      style={{ width: '100%' }}
      placeholder={editMode ? placeholder : ''}
      formatter={(value) => `${value}`.replace(mask, ',')}
      //formatter={(value) => `$ ${value}`.replace(mask, ',')}
      //parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
    />
  );
};
