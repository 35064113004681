import { ApiRoutes } from '../../constants/ApiRouteConstants';
import {
  PagerFilters,
  convertToUrlParamiter,
} from '../../helpers/filterHelper';
import { AppToken, AppChangeEmailModel } from '../../models/appMovil/AppToken';
import { apiGet, apiPost } from '../BaseService';
import { PageResult } from '../../models/core/PageResult';

export interface AppTokenFilters extends PagerFilters {
  buscar?: string;
}

const listar = async (
  filters?: AppTokenFilters
): Promise<PageResult<AppToken> | undefined> => {
  try {
    const url = ApiRoutes.appTokenList + convertToUrlParamiter(filters);
    const result = await apiGet<PageResult<AppToken>>(url);
    return result;
  } catch (err) {
    console.log(err);
  }
  return undefined;
};

const changeEmail = async (
  model: AppChangeEmailModel
): Promise<boolean | undefined> => {
  try {
    const respose = await apiPost<AppChangeEmailModel, boolean>(
      ApiRoutes.AppChangeEmail,
      model
    );
    return respose;
  } catch (er) {}
  return undefined;
};

export const TokenService = {
  listar,
  changeEmail,
};
