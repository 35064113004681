import { DropdownItem } from "../../components/forms/dropdown/Dropdown";
import { ApiRoutes } from "../../constants/ApiRouteConstants";
import { Funcion } from "../../models/core/Funcion";
import { apiGet } from "../BaseService";


const funciones = async (): Promise<Funcion[] | undefined> => {
  return await apiGet<Funcion[]>(ApiRoutes.funciones);
};

const funcionesLookup = async (): Promise<DropdownItem[] | undefined> => {
  return await apiGet<DropdownItem[]>(ApiRoutes.funcionesLookup);
};

export const AplicacionService = {
  funciones,
  funcionesLookup
};
