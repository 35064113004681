import {
  Card,
  Col,
  Form,
  Row,
  Space,
  Spin,
  Switch,
  Transfer,
  message,
} from 'antd';
import Button from 'antd-button-color';
import { TransferDirection } from 'antd/es/transfer';
import React, { useCallback, useState } from 'react';

import { InputExt } from '../../../components/forms/InputExt';
import { RouteUrl } from '../../../constants/RouteConstants';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { Funcion } from '../../../models/core/Funcion';
import type { Rol } from '../../../models/core/Rol';
import { AplicacionService } from '../../../services/core/AplicacionService';
import { RolService } from '../../../services/core/RolService';
import { useHistory } from 'react-router';

export interface RolProps {}

const RolForm: React.FC<RolProps> = () => {
  const history = useHistory();
  const [editing, setEditing] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [form] = Form.useForm<Rol>();
  const [items, setItems] = useState<Funcion[]>([]);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const [messageApi, contextHolder] = message.useMessage();

  const cargarDatos = useCallback(async (): Promise<void> => {
    const urlSection = window.location.pathname.split('/');
    const id = urlSection[3];

    const funciones = await AplicacionService.funciones();
    setItems(funciones ?? []);
    if (id) {
      const result = await RolService.get(id);
      if (result) {
        form.setFieldsValue(result);
        setTargetKeys(result.funciones ?? []);
      }
    } else {
      setEditing(true);
      setIsNew(true);
    }
  }, [form]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, []);

  const onChange = (
    nextTargetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  async function onFinish(values: Rol): Promise<void> {
    const rol: Rol = { ...values, funciones: targetKeys };
    const result = await RolService.update(rol);

    if (result) {
      messageApi.open({
        type: 'success',
        content: 'Guardado exitoso',
      });
      setEditing(false);
    } else {
      messageApi.open({
        type: 'error',
        content: 'Se produjo un error al guardar',
      });
    }
  }

  return (
    <Spin spinning={loader.loading} delay={500}>
      {contextHolder}
      <Card
        title='Ficha de Rol'
        extra={
          <Space>
            {!editing && (
              <>
                <Button type='warning' onClick={() => setEditing(true)}>
                  Editar
                </Button>
              </>
            )}
            {editing && (
              <>
                <Button
                  type='warning'
                  onClick={() =>
                    isNew ? history.push(RouteUrl.Roles) : setEditing(false)
                  }
                >
                  Cancelar
                </Button>
                <Button type='success' onClick={() => form.submit()}>
                  Grabar
                </Button>
              </>
            )}
          </Space>
        }
      >
        <Form
          form={form}
          // initialValues={current}
          name='quickForm'
          layout='vertical'
          // labelCol={{ span: 20 }}
          //wrapperCol={{ span:  }}
          autoComplete='off'
          disabled={!editing}
          onFinish={onFinish}
          //style={{ maxWidth: 600 }}
        >
          <Form.Item name='rolId' hidden />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className='gutter-row' span={8}>
              <Form.Item
                label='Nombre'
                name='nombre'
                required
                rules={[{ required: true, message: 'Falta el nombre' }]}
              >
                <InputExt
                  placeholder='Nombre'
                  maxLength={50}
                  showCount
                  readOnly={!editing}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item
                label='Es administrador'
                name='esAdmin'
                valuePropName='checked'
              >
                <Switch
                  checkedChildren='Administrador'
                  unCheckedChildren='Usuario'
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item label='Estado' name='activo' valuePropName='checked'>
                <Switch checkedChildren='Activo' unCheckedChildren='Inactivo' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card title='Funciones'>
        <Transfer
          disabled={!editing}
          listStyle={{
            width: '50%',
            height: 300,
          }}
          dataSource={items}
          showSearch
          filterOption={(input, option) =>
            (option?.nombre!.toLowerCase() ?? '').includes(input.toLowerCase())
          }
          titles={['Disponibles', 'Asignados']}
          operations={['Asignar', 'Quitar']}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={onChange}
          onSelectChange={onSelectChange}
          // onScroll={onScroll}
          rowKey={(item: Funcion) => item.funcionId}
          render={(item: Funcion) => item.nombre}
        />
      </Card>
    </Spin>
  );
};

export default RolForm;
