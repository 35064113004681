import type { PageResult } from "../../../models/core/PageResult";
import { Card, Col, Input, Pagination, Row, Space, Spin, Tag } from "antd";
import Button from "antd-button-color";
import Table, { ColumnsType } from "antd/es/table";
import React, { useCallback, useState } from "react";
import { RouteUrl } from "../../../constants/RouteConstants";
import { useAsyncCall } from "../../../hooks/useAsyncCall";
import { configApp } from "../../../services/configApp";
import { useHistory } from "react-router";
import {
  Expediente,
  ExpedienteEstados,
  ExpedienteEstadosColor,
} from "../../../models/sas/Expediente";
import { ExpedienteModel } from "../../../models/sas/ExpedienteModel";
import {
  ExpedienteFilters,
  ExpedienteService,
} from "../../../services/accionSocial/ExpedienteService";

export interface ExpedienteProps {}

const ExpedientesList: React.FC<ExpedienteProps> = () => {
  const history = useHistory();
  const [pageResult, setPageResult] = useState<PageResult<Expediente>>();
  const [page, setPage] = useState<number>(1);
  const [buscar, setBuscar] = useState<string>("");

  const columns: ColumnsType<ExpedienteModel> = [
    {
      title: "Expedientes",
      key: "expediente",
      width: 100,
      render: (_, row) => (
        <a href={`${RouteUrl.Expediente}/${row.expedienteId}`}>{row.numero}</a>
      ),
    },
    {
      title: "Comprobante",
      key: "comprobante",
      dataIndex: "comprobante",
      width: 100,
    },
    {
      title: "Fecha",
      key: "fecha",
      width: 100,
      render: (_, row) => (
        <span> {new Date(row.fecha ?? "").toLocaleDateString()}</span>
      ),
    },
    {
      title: "Afiliado",
      key: "afiliadoNombres",
      dataIndex: "afiliadoNombres",
    },
    {
      title: "Total",
      key: "total",
      width: 100,
      render: (_, row) => (
        <span style={{ alignItems: "end" }}>{row.total}</span>
      ),
    },
    {
      title: "Reintegro",
      key: "reintegro",
      width: 100,
      render: (_, row) => <span>{row.reintegro}</span>,
    },
    {
      title: "",
      key: "estado",
      render: (_, row) => (
        <Tag color={ExpedienteEstadosColor[row.estado ?? 0]}>
          {ExpedienteEstados[row.estado ?? 0]}
        </Tag>
      ),
    },
  ];

  const cargarDatos = useCallback(async (): Promise<void> => {
    const filters: ExpedienteFilters = {
      buscar,
      page: page,
      count: configApp.ROW_PER_PAGE,
    };
    const result = await ExpedienteService.listar(filters);
    if (result) {
      setPageResult(result);
    }
  }, [buscar, page]);

  const loader = useAsyncCall(async () => {
    await cargarDatos();
  }, []);

  function handlerChangePage(page: number, pageSize: number): void {
    setPage(page);
  }

  return (
    <Card
      title="Expedientes"
      extra={
        <Space>
          <Button
            type="warning"
            onClick={() => history.push(RouteUrl.Expediente)}
          >
            Crear nuevo
          </Button>
        </Space>
      }
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Input
            placeholder="Buscar"
            value={buscar}
            onChange={(e: any) => setBuscar(e.target.value)}
          />
        </Col>

        <Col span={24}>
          <Spin spinning={loader.loading} delay={500}>
            <Table
              columns={columns}
              dataSource={pageResult?.data}
              size="small"
              pagination={false}
            />
            {pageResult?.pagination && (
              <Pagination
                size="small"
                current={pageResult.pagination.page}
                total={pageResult?.pagination.rows}
                pageSize={pageResult?.pagination.count}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} de ${total} items`
                }
                onChange={handlerChangePage}
              />
            )}
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};

export default ExpedientesList;
