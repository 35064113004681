import { PageResult } from "../../models/core/PageResult";
import { Usuario } from "../../models/core/Usuario";
import { apiGet, apiPost } from "../BaseService";
import { ApiRoutes } from "../../constants/ApiRouteConstants";


const listar = async (buscar: string, page?: number, count?: number): Promise<PageResult<Usuario> | undefined> => {
  try {
    let sep = "?";
    let url = ApiRoutes.usuarios;

    if (buscar) {
      url += `${sep}buscar=${buscar}`;
      sep = "&";
    }
    if (page && count) {
      url += `${sep}page=${page}&count=${count}`;
      sep = "&";
    }
    const models = await apiGet<PageResult<Usuario>>(url);
    return models;
  } catch (err) {
    console.log(err);
  }
  return undefined;
};

const get = async (id: string): Promise<Usuario | undefined> => {
  try {
    const model = await apiGet<Usuario>(ApiRoutes.usuario.replace(":id", id));
    return model;
  } catch (er) {}
  return undefined;
};

const update = async (model: Usuario): Promise<Usuario | undefined> => {
  try {
    const respose = await apiPost<Usuario, Usuario>(ApiRoutes.usuarioUpdate, model);
    return respose;
  } catch (er) {}
  return undefined;
};

export const UsuarioService = {
  listar,
  get,
  update
};
