import {
  AuditOutlined,
  ContactsOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { Layout, Menu, MenuProps, theme } from "antd";
import "antd-button-color/dist/css/style.css";
import "antd/dist/reset.css";
import Sider from "antd/es/layout/Sider";
import { Content, Footer, Header } from "antd/es/layout/layout";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../src/assets/logo.png";
import { Icon } from "../components/forms/icon/Icon";
import { RouteUrl } from "../constants/RouteConstants";
import "../styles/AntDesingExt.css";

export interface GestionLayoutProps {}

type MenuItem = Required<MenuProps>["items"][number];

export const GestionLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const style: React.CSSProperties = { width: 10 };

  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click ", e);
    history.push(e.key);
  };

  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  };

  const items: MenuItem[] = [
    getItem("Gremiales", "sub1", <IdcardOutlined />, [
      getItem(
        "Afiliados",
        RouteUrl.Afiliados,
        <Icon icon="fa-address-card" style={style} />
      ),
      getItem(
        "Tokens",
        RouteUrl.AppTokens,
        <Icon icon="fa-mobile-android-alt" style={style} />
      ),
      getItem(
        "Empresas",
        RouteUrl.Empresas,
        <Icon icon="fa-train" style={style} />
      ),
      getItem(
        "Seccionales",
        RouteUrl.Seccionales,
        <Icon icon="fa-map-marked-alt" style={style} />
      ),
    ]),
    getItem("Accion Social", "sub2", <ContactsOutlined />, [
      getItem("Rubros", RouteUrl.Rubros, <AuditOutlined />),
      getItem("Ciclos", RouteUrl.Ciclos, <AuditOutlined />),
      getItem("Expedientes", RouteUrl.Expedientes, <AuditOutlined />),
    ]),
    getItem(
      "Panel de control",
      "pctrl",
      <Icon icon="fa-cogs" style={style} />,
      [
        getItem(
          "Usuarios",
          RouteUrl.Usuarios,
          <Icon icon="fa-user-lock" style={style} />
        ),
        getItem(
          "Roles",
          RouteUrl.Roles,
          <Icon icon="fa-users-class" style={style} />
        ),
      ]
    ),
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div
          style={{
            height: 60,
            margin: 15,
          }}
        >
          <img className="logo" src={logo} />
        </div>
        <Menu
          onClick={onClick}
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items}
        />
      </Sider>
      <Layout className="site-layout">
        <Header style={{ padding: 0, background: colorBgContainer }} />
        <Content style={{ margin: "0 16px" }}>
          {/* <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>User</Breadcrumb.Item>
          <Breadcrumb.Item>Bill</Breadcrumb.Item>
        </Breadcrumb> */}
          {children}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          One4All Developers ©2023 Ver.: 1.0.0
        </Footer>
      </Layout>
    </Layout>
  );
};

export default GestionLayout;
