import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Space,
  Spin,
} from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteUrl } from '../../../constants/RouteConstants';
import { AuthenticateRequest } from '../../../models/core/AuthenticateRequest';
import { userService } from '../../../services/core/UserService';
import './LoginDesign.css';
import logo from '../../../assets/logo.png';

export interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const onFinish = async (values: AuthenticateRequest): Promise<void> => {
    setLoading(true);
    setHasError(false);
    try {
      const userInfo = await userService.Authenticate(values);
      if (userInfo) {
        history.push(RouteUrl.Dashboard);
      }
    } catch (err) {
      console.log(err);
    }
    setHasError(true);
    setLoading(false);
  };
  return (
    <Layout
      style={{
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        style={{
          width: 400,
          height: 700,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {hasError && (
          <Alert
            message='No se pudo loguear'
            description='Verifique el usuario y o la contraseña.'
            type='error'
          />
        )}
        <Spin spinning={loading} delay={500}>
          <Space direction='vertical' size={'large'}>
            <Image src={logo} preview={false} height={300}>
              {' '}
            </Image>
            <Form
              name='normal_login'
              className='login-form'
              initialValues={{ remember: true }}
              layout='vertical'
              onFinish={onFinish}
            >
              <Form.Item
                label='Nombre de Usuario'
                name='username'
                rules={[
                  { required: true, message: 'Ingrese su nombre de usuario' },
                ]}
              >
                <Input
                  prefix={<UserOutlined className='site-form-item-icon' />}
                  placeholder='Email / nombre de usuario'
                />
              </Form.Item>
              <Form.Item
                label='Contraseña'
                name='password'
                rules={[{ required: true, message: 'Ingrese su contraseña' }]}
              >
                <Input
                  prefix={<LockOutlined className='site-form-item-icon' />}
                  type='password'
                  placeholder='Contraseña'
                />
              </Form.Item>
              <Form.Item>
                <Form.Item name='remember' valuePropName='checked' noStyle>
                  <Checkbox>Recordar</Checkbox>
                </Form.Item>

                <a className='login-form-forgot' href=''>
                  Olvide mi contraseña
                </a>
              </Form.Item>

              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                >
                  Ingresar
                </Button>
                O <a href=''>Registrar ahora</a>
              </Form.Item>
            </Form>
          </Space>
        </Spin>
      </Card>
    </Layout>
  );
};

export default Login;
