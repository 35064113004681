import { Card, Divider, Space } from 'antd';
import React from 'react';
import { CardButton } from '../../components/forms/cardButton/cardButton';
import { RouteUrl } from '../../constants/RouteConstants';

export interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  const style: React.CSSProperties = { width: 200 };
  return (
    <Card title='Dashboard'>
      <Space size={[8, 16]} wrap>
        <CardButton
          iconName='fa-id-card'
          title='Listado de afiliados'
          href={RouteUrl.Afiliados}
          style={style}
        />
        <CardButton
          iconName='fa-mobile-android-alt'
          title='AppMovil Token'
          href={RouteUrl.AppTokens}
          style={style}
        />
        <CardButton
          iconName='fa-train'
          title='Empresas'
          href={RouteUrl.Empresas}
          style={style}
        />
        <CardButton
          iconName='fa-map-marked-alt'
          title='Seccionales'
          href={RouteUrl.Seccionales}
          style={style}
        />
      </Space>
      <Divider />
      <Space size={[8, 16]} wrap>
        <CardButton
          iconName='fa-user-lock'
          title='Usuarios'
          href={RouteUrl.Usuarios}
          style={style}
        />
        <CardButton
          iconName='fa-users-class'
          title='Roles'
          href={RouteUrl.Roles}
          style={style}
        />
      </Space>
    </Card>
  );
};

export default Dashboard;
