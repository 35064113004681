import { Result } from "antd";
import { createBrowserHistory } from "history";
import { Link, Route, Router, Switch, useHistory } from "react-router-dom";
import { RouteUrl } from "./constants/RouteConstants";
import { Dashboard } from "./pages/Dashboard";
import GestionLayout from "./pages/GestionLayout";
import AfiliadoForm from "./pages/gremial/afiliados/AfiliadoForm";
import AfiliadoList from "./pages/gremial/afiliados/AfiliadoList";
import EmpresaForm from "./pages/gremial/empresas/EmpresaForm";
import EmpresaList from "./pages/gremial/empresas/EmpresaList";
import SeccionalForm from "./pages/gremial/seccionales/SeccionalForm";
import SeccionalList from "./pages/gremial/seccionales/SeccionalList";
import TokenList from "./pages/gremial/token/TokenList";
import RolForm from "./pages/panelControl/roles/RolForm";
import RolList from "./pages/panelControl/roles/RolList";
import UsuarioForm from "./pages/panelControl/usuarios/UsuarioForm";
import UsuarioList from "./pages/panelControl/usuarios/UsuarioList";
import Home from "./pages/public/home/Home";
import Login from "./pages/public/login/Login";
import { PrivateRoute } from "./components/PrivateRoute";
import RubrosList from "./pages/accionSocial/Rubros/RubrosList";
import ExpedientesList from "./pages/accionSocial/Expedientes/ExpedientesList";
import ExpedienteForm from "./pages/accionSocial/Expedientes/ExpedienteForm";

type Properties = {
  history?: History;
};

const App: React.FC<Properties> = () => {
  const history = useHistory();

  return (
    <Router history={history}>
      <GestionLayout>
        <Switch>
          <PrivateRoute path={RouteUrl.Dashboard}>
            <Dashboard />
          </PrivateRoute>

          <PrivateRoute path={RouteUrl.AppTokens}>
            <TokenList />
          </PrivateRoute>

          <PrivateRoute path={RouteUrl.Afiliado} exact>
            <AfiliadoForm />
          </PrivateRoute>
          <PrivateRoute path={RouteUrl.AfiliadoId} exact>
            <AfiliadoForm />
          </PrivateRoute>
          <PrivateRoute path={RouteUrl.Afiliados} exact>
            <AfiliadoList />
          </PrivateRoute>

          <PrivateRoute path={RouteUrl.Rubros} exact>
            <RubrosList />
          </PrivateRoute>

          <PrivateRoute path={RouteUrl.Expediente} exact>
            <ExpedienteForm />
          </PrivateRoute>
          <PrivateRoute path={RouteUrl.ExpedienteId} exact>
            <ExpedienteForm />
          </PrivateRoute>
          <PrivateRoute path={RouteUrl.Expedientes} exact>
            <ExpedientesList />
          </PrivateRoute>

          <PrivateRoute path={RouteUrl.Empresa}>
            <EmpresaForm />
          </PrivateRoute>
          <PrivateRoute path={RouteUrl.EmpresaId}>
            <EmpresaForm />
          </PrivateRoute>
          <PrivateRoute path={RouteUrl.Empresas}>
            <EmpresaList />
          </PrivateRoute>

          <PrivateRoute path={RouteUrl.Seccional}>
            <SeccionalForm />
          </PrivateRoute>
          <PrivateRoute path={RouteUrl.SeccionalId}>
            <SeccionalForm />
          </PrivateRoute>
          <PrivateRoute path={RouteUrl.Seccionales}>
            <SeccionalList />
          </PrivateRoute>

          <PrivateRoute path={RouteUrl.Usuario}>
            <UsuarioForm />
          </PrivateRoute>
          <PrivateRoute path={RouteUrl.UsuarioId}>
            <UsuarioForm />
          </PrivateRoute>
          <PrivateRoute path={RouteUrl.Usuarios}>
            <UsuarioList />
          </PrivateRoute>

          <PrivateRoute path={RouteUrl.Rol}>
            <RolForm />
          </PrivateRoute>
          <PrivateRoute path={RouteUrl.RolId}>
            <RolForm />
          </PrivateRoute>
          <PrivateRoute path={RouteUrl.Roles}>
            <RolList />
          </PrivateRoute>
        </Switch>
      </GestionLayout>
    </Router>

    // <Routes>
    //   <Route path='/' element={<Login />}>
    //     <Route index element={<Login />} />
    //     <Route path='login' element={<Login />} />
    //   </Route>
    //   <Route element={<GestionLayout />}>

    //     {/* <Route path='/accionsocial' element={<GestionLayout />}>
    //     <Route path='reintegros' element={<ReintegroList />} />
    //     <Route path='reintegro/:id' element={<UsuarioForm />} />
    //     <Route path='reintegro' element={<UsuarioForm />} /> */}

    //   </Route>
    //   <Route
    //     path='*'
    //     element={
    //       <Result
    //         status='404'
    //         title='404'
    //         subTitle='Lo sentimos, la pagina buscado no existe.'
    //         extra={<a href='/'>Volver</a>}
    //       />
    //     }
    //   />
    // </Routes>
  );
};

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
