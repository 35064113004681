import { Card, Col, Row } from "antd";
import React, { useMemo } from "react";
import Button from "antd-button-color";
import { Familiar, Familiarcolores } from "../../../../models/gremial/Familiar";
import { Genero, RelacionFamiliar } from "../../../../models/Enums";
import Label from "../../../../components/forms/Label";
import dayjs from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";
import "./CardFamiliar.css";
import { AuthorizationHelper } from "../../../../helpers/AuthorizationHelper";
import { Permisos } from "../../../../helpers/Permisos";

dayjs.extend(objectSupport);

export interface Properties {
  familiar: Familiar;
  onEdit: (familiar: Familiar) => void;
}

export const CardFamiliar: React.FC<Properties> = ({ familiar, onEdit }) => {
  const auth = new AuthorizationHelper();

  const Icono = useMemo(() => {
    let icono = "fas fa-user";
    let color = Familiarcolores.gris;

    if (familiar.genero === Genero.Masculino) {
      icono = "fas fa-male";
      color = Familiarcolores.azul;
    } else if (familiar.genero === Genero.Femenino) {
      icono = "fas fa-female";
      color = Familiarcolores.rosa;
    }

    if (familiar.relacionTipo === RelacionFamiliar.Conyuge) {
      icono = "fas fa-rings-wedding";
    } else {
      if (familiar.edad) {
        if (familiar.edad <= 3) {
          icono = "fas fa-baby";
        } else if (familiar.edad < 18) {
          icono = "fas fa-child";
        }
      }
    }

    return `${icono} fa-5x ${Familiarcolores[color ?? 2]}`;
  }, [familiar]);

  const Relacion = useMemo(() => {
    let relacion = "-";
    switch (familiar?.relacionTipo) {
      case RelacionFamiliar.Conyuge:
        relacion =
          familiar.genero === Genero.Desconocido
            ? "Conyuge"
            : familiar.genero === Genero.Masculino
            ? "Esposo"
            : "Esposa";
        break;
      case RelacionFamiliar.Hijo:
        relacion = familiar.genero === Genero.Femenino ? "Hija" : "Hijo";
        break;
      default:
        relacion = "Familiar a cargo";
        break;
    }
    return relacion;
  }, [familiar.genero, familiar?.relacionTipo]);

  const canAfiliados = auth.canAccess(Permisos.sog_afiliados);

  return (
    <div className="card-familiar">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col md={8} lg={8}>
          <div className="card-familiar__icono">
            <i className={Icono} style={{ fontSize: "80px" }}></i>
            <div>{Relacion}</div>
          </div>
        </Col>
        <Col md={24} lg={12}>
          <div className="card-familiar__datos">
            <Label text="Nombre/s y Apellido/s">{familiar.nombres}</Label>
            <Label text="Documento">{familiar.documento}</Label>
            <Label text="Fecha de Nacimiento">
              {new Date(familiar.fechaNacimiento ?? "").toLocaleDateString()}
            </Label>
            <Label text="Edad">{familiar.edad}</Label>
          </div>
        </Col>
      </Row>
      {canAfiliados && (
        <Button type="warning" onClick={() => onEdit(familiar)}>
          Editar
        </Button>
      )}
    </div>
  );
};
